import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Update_Salary_Details() {
  return (
    <div className='P1'>
    <section className="my-lg-9 my-5 ">
      <div className="container ">
          <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 offset-lg-0 col-md-12 ">
                  
                          <div className="card bg-light shadow">
                              <div className="card-body p-5">
                                    <h3 className="mb-3 d-flex justify-content-center" style={{fontWeight:'bold'}}>Update Salaray Details</h3>
                                    <Form>
                                      <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Name:</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Name" />
                                        <Form.Label>ID No:</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Id No" />
                                        <Form.Label>Designation:</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Designation" />
                                        <Form.Label>Gross Salary:</Form.Label>
                                        <Form.Control type="number" placeholder="0" />
                                        <Form.Label>Salary per day:</Form.Label>
                                        <Form.Control type="number" placeholder="0" />
                                        <Row>
                                        <Form.Group as={Col} className='mb-3'>
                                       
                                            <Form.Label>Salary per month:</Form.Label>
                                            <Form.Control type="number" placeholder="0" />
                                        </Form.Group>  
                                        <Form.Group as={Col} className='mb-3'>

                                            <Form.Label>Year:</Form.Label>
                                            <Form.Control type="number" placeholder="0" />
                                            
                                        </Form.Group>
                                        </Row>
                                        <Form.Label>Total LOP Day's:</Form.Label>
                                        <Form.Control type="number" placeholder="0" />
                                        <Form.Label>Present Day's:</Form.Label>
                                        <Form.Control type="number" placeholder="0" />
                                        <Form.Label>Deduction's:</Form.Label>
                                        <Form.Control type="number" placeholder="0" />
                                        <Form.Label>Extra Allowance Amount:</Form.Label>
                                        <Form.Control type="number" placeholder="0" />
                                        <Form.Label>Net Amount:</Form.Label>
                                        <Form.Control type="number" placeholder="0" />
                                        <hr/>
                                        <Form.Label>Paid Amount:</Form.Label>
                                        <Form.Control type="number" placeholder="0" />
                                      </Form.Group>
                                      <Button variant='primary' style={{width:'100%'}}>Update</Button>
                                    </Form>
                              </div>
                            </div>
                       
                </div>
            </div>
        </div>
    </section>
  
    </div>
  )
}

export default Update_Salary_Details