import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { IoPerson } from "react-icons/io5";
import { Link ,Outlet} from 'react-router-dom';
export default function Emp() {
  return (
    <div>
        <Navbar collapseOnSelect expand="lg" className="bg-body shadow">
      <Container>
      <IoPerson size={30}/>
        <Navbar.Brand className='ms-2'>Employee</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
       {/* 1st */}
       <NavDropdown title="Employee Register" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1"  as={Link} to='/dashboard/emp/Emp_Register'>Employee Register</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2"  as={Link} to='/dashboard/emp/primary_info'>
               Employee Primary Information
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3"  as={Link} to='/dashboard/emp/address'>Employee Address </NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.3"  as={Link} to='/dashboard/emp/add'>Add </NavDropdown.Item> */}
            </NavDropdown>
         {/* 1st */}
         {/* 2nd */}
            <NavDropdown title="Work Profile" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1"  as={Link} to='/dashboard/emp/designation'>Job Profile</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2"  as={Link} to='/dashboard/emp/timetrack'>
                Salary Details
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2"  as={Link} to='/dashboard/emp/timetrack'>
                Attendance
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3"  as={Link} to='/dashboard/emp/assignwork' >Assigned Work</NavDropdown.Item>
            </NavDropdown>
            {/* 2nd */}
            <NavDropdown title="Bank Details" id="collapsible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1"  as={Link} to='/dashboard/emp/bankdetail'>Bank Detail</NavDropdown.Item>
            </NavDropdown>
            {/* 3rd */}
            <NavDropdown title="Salary" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1"  as={Link} to='/dashboard/emp/salarydetails'>Salary Details</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2"  as={Link} to='/dashboard/emp/update'>
                Update salary Details
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3"  as={Link} to='/dashboard/emp/salaryslip'>Salary Slip</NavDropdown.Item>
            </NavDropdown>
            {/* 3rd */}
            {/* 4th */}
            <NavDropdown title="Leave" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1"  as={Link} to='/dashboard/emp/updateleave'>Update Leave Approval</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2"  as={Link} to='/dashboard/emp/applyleave'>
            Apply Leave
              </NavDropdown.Item>
            </NavDropdown>
            {/* 4th */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div>
        <Outlet></Outlet>
    </div>
    </div>
  )
}
