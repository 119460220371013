import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Card from 'react-bootstrap/Card';
import './Mobile_App_Development.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { BiCustomize } from "react-icons/bi";
import { TiShoppingCart } from "react-icons/ti";
import { GrServices } from "react-icons/gr";
import { Button } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


function ECommerce()
{
  return (
    
        <div className='m-3'>


<div className='headerc d-flex align-items-center justify-content-center'>

<Breadcrumb className='custom-breadcrumb'>

    <Breadcrumb.Item>
    <Link  to="/" className="" >Home</Link>

    </Breadcrumb.Item>
   
    <Breadcrumb.Item>
    <Link  to="/ecommerce" className="active" >E-Commerce Development</Link>
    {/* <a as="Link" to="/service" className="active" >Service</a> */}
    
     </Breadcrumb.Item>
    
</Breadcrumb>

</div>     

            <Container fluid>
                <div className='div1-ec'>
                    <div className="col-12 col-md-12">
                      <Row><Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                      <Card className='main-title-ec-c'>
                         <h1 className="main-title mt-2">E-Commerce Development</h1>
                         <p className="desc mb-0">"Discover More, Shop Better: Your One-Stop E-Commerce Destination at IRTCM !"</p>
                      </Card>
                      </Col>
                      </Row>
                         <Row>
                              <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <img className='ec1' src="../img/service/ECOMMERCE.png"  width='50%'></img>
                              </Col>
                         </Row>
    
                       
                      </div>
                </div>
                <div className='div2-ec'>
                  <div >
                  <Row>
                    <Col xs={12} md={12}>
                      <p className='ecomh1 d-flex justify-content-center align-Items-center mt-5 mb-4'>E-Commerce Development</p>
                    </Col>
                  </Row>
                  <Row className=''>
                    <Col xs={12} md={6} className='d-flex justify-content-center align-Items-center mt-auto mb-auto'>
                        <img className='ec2 mb-5' src="../img/service/ECOMMERCE2.png"  width='50%'></img>
                    </Col>
                    <Col xs={12} md={6} className='' >
                        <p className='otext1'>IRTCM claims more than 5 years of expertise in e-commerce development. Our focus is not only on 
                          establishing your online presence but also on significantly increasing your sales, traffic and customer
                           retention rates.
                          <br/><br/>
                        Whether you are looking for e-commerce design or customized development solutions, our team of experienced 
                        developers has the in-depth knowledge required to deliver solutions that catalyze the growth of your e-commerce 
                        venture.
                        <br/><br/>
                        We recognize the challenges inherent in managing an e-commerce platform and assure you of our unparalleled support.
                         Our commitment goes beyond initial development; We ensure seamless operation of your e-commerce site, guarantee maximum returns and superior performance compared.</p>
                    </Col>
                  </Row>
                  </div>
                </div>
                <div className='div3-ec'>
                  <div className=''>
                  <Row>
                    <Col xs={12} md={12}>
                      <p className='ecomh2 d-flex justify-content-center align-Items-center'>Overall E-commerce Services We Offer</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <p className='mt-3 ecomtxt'>With our expertise, you can rest assured that your e-commerce venture will be equipped with cutting-edge functionalities, ensuring a seamless and intuitive user experience. Let us empower your business to thrive in the dynamic world of e-commerce, where success is defined by innovation and adaptability.</p>
                    </Col>
                  </Row>
                  <Row className='d-flex cardrow'>
                    <Col xs={12} md={4}>
                      <Card className='m-2 ecard1' style={{width:'80%'}}>
                        <Row>
                        <Col xs={4} md={4}>
                              <BiCustomize style={{width:'5vw',height:'auto',color:'white'}} className='m-3'></BiCustomize>
                        </Col>
                        <Col xs={8} md={8}>
                                  <p className='ectxt1'>Custome ECommerce</p>
                        </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={12} md={4}>
                      <Card className='m-2 ecard1' style={{width:'80%'}}>
                        <Row>
                        <Col xs={4} md={4}>
                              <TiShoppingCart style={{width:'5vw',height:'auto',color:'white'}} className='m-3'></TiShoppingCart>
                        </Col>
                        <Col xs={8} md={8}>
                                  <p className='ectxt1'>Shopping Cart</p>
                        </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={12} md={4}>
                      <Card className='m-2 ecard1' style={{width:'80%'}}>
                        <Row>
                        <Col xs={4} md={4}>
                              <GrServices style={{width:'5vw',height:'auto',color:'white'}} className='m-3'></GrServices>
                        </Col>
                        <Col xs={8} md={8}>
                                  <p className='ectxt3'>Maintainence & Support</p>
                        </Col>
                        </Row>
                      </Card>
                    </Col>
                    
                  </Row>
                  <Row><div className='mb-5'><br/></div></Row>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col xs={12} md={12}>
                      <p className='eslogan d-flex justify-content-center align-Items-center text-dark'>Elevate Your Online Success with Our Dedicated E-commerce Development Team !</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                      <Button className='getintouch mb-3'>Get In Touch</Button>
                    </Col>
                  </Row>
                </div>
                
            </Container>
        </div>
  )
}

export default ECommerce;