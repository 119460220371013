import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
 import BasicNav from './component/BasicNav';
 import Home from './component/Home';
import AboutUs from './component/AboutUs';
import ContactUs from './component/ContactUs';
import Footer from './component/Footer';
import Service from './component/Service';
import Portfolio from './component/Portfolio';
import LoginForm from './component/LoginForm';
import Career from './component/Career';
import Disclaimer from './component/Disclaimer';
import Privacy from './component/Privacy';
import TermsCondition from './component/TermsCondition';
import {RouterProvider,createBrowserRouter} from 'react-router-dom';
import Dashboard from './component/Dashboard';
import Emp from './component/Emp';
import Employee_Login from './component/Employee/Employee_Login';
import Emp_Primary_Info from './component/Employee/Emp_Primary_Info';
import Emp_Address_Info from './component/Employee/Emp_Address_Info';
import Add_Emp from './component/Employee/Add_Emp';
import Emp_Designation_Info from './component/Employee/Emp_Designation_Info';
import Time_Tracking from './component/Employee/Time_Tracking';
import Emp_Salary_Details from './component/Employee/Emp_Salary_Details';
import Update_Salary_Details from './component/Employee/Update_Salary_Details';
import Salary_Slip from './component/Employee/Salary_Slip';
import Update_Leave_Approval from './component/Employee/Update_Leave_Approval';
import Apply_Leave from './component/Employee/Apply_Leave';
import Admin_Work from './component/Employee/Admin_Work';
import Forgot from './component/Forgot';
import Digital_Marketing from './component/Digital_Marketing';
import Mobile_App_Development from './component/Mobile_App_Development';
import Web_Development from './component/Web_Development';
import ECommerce from './component/ECommerce';
import Emp_Register from './component/Employee/Emp_Register';
import Bank_Details from './component/Employee/Bank_Details';
import SoftwareDev from './component/SoftwareDev';
const router=createBrowserRouter([ 
  {
    path:"/",
    element:<Layout/>,
    children:[
      {
        index:true,
        element:<Home></Home>
      },
      {
        path:'/aboutus',
        element:<AboutUs></AboutUs>
      },
      {
        path:'/service',
        element:<Service></Service>
      },
      {
        path:'/career',
        element:<Career></Career>
      },
      {
        path:'/portfolio',
        element:<Portfolio></Portfolio>
      },
      {
        path:'/contactus',
        element:<ContactUs></ContactUs>
      },
      {
        path:'/disclaimer',
        element:<Disclaimer></Disclaimer>
      },
      {
        path:'/privacy',
        element:<Privacy></Privacy>
      },
      {
        path:'/terms_condition',
        element:<TermsCondition></TermsCondition>
      },
      {
        path:'/loginform',
        element:<LoginForm></LoginForm>
      },
      {
        path:'/forgot',
        element:<Forgot></Forgot>
      },
      {
        path:'/digital',
        element:<Digital_Marketing></Digital_Marketing>
      },
      {
        path:'/mobile',
        element:<Mobile_App_Development></Mobile_App_Development>
      },
      {
        path:'/web',
        element:<Web_Development></Web_Development>
      },
      {
        path:'/ecommerce',
        element:<ECommerce></ECommerce>
      },
      {
        path:'/customsoftware',
        element:<SoftwareDev></SoftwareDev>
      }
     
    ]
  },
  {
    path:'/dashboard',
    element:<Layout1></Layout1>,
    children:[
    {
        path:'/dashboard/emp',
        element:<Layout2></Layout2>,
        children:[
          {
            path:'/dashboard/emp/Emp_register',
            element:<Emp_Register></Emp_Register>
          },
          {
            path:'/dashboard/emp/primary_info',
            element:<Emp_Primary_Info></Emp_Primary_Info>
          },
          {
            path:'/dashboard/emp/address',
            element:<Emp_Address_Info></Emp_Address_Info>
          },
          {
            path:'/dashboard/emp/add',
            element:<Add_Emp></Add_Emp>
          },
          {
            path:'/dashboard/emp/designation',
            element:<Emp_Designation_Info></Emp_Designation_Info>
          },
          {
            path:'/dashboard/emp/timetrack',
            element:<Time_Tracking></Time_Tracking>
          },
          {
            path:'/dashboard/emp/assignwork',
            element:<Admin_Work></Admin_Work>
          },
          {
            path:'/dashboard/emp/salarydetails',
            element:<Emp_Salary_Details></Emp_Salary_Details>
          },
          {
            path:'/dashboard/emp/update',
            element:<Update_Salary_Details></Update_Salary_Details>
          },
          {
            path:'/dashboard/emp/salaryslip',
            element:<Salary_Slip></Salary_Slip>
          },
          {
            path:'/dashboard/emp/updateleave',
            element:<Update_Leave_Approval></Update_Leave_Approval>
          },
          {
            path:'/dashboard/emp/applyleave',
            element:<Apply_Leave></Apply_Leave>
          },
          {
            path:'/dashboard/emp/bankdetail',
            element:<Bank_Details></Bank_Details>
          }
        ]
      }
      
    ]
  }
 ])
function App() {
  return (
    <div className="App">
      <RouterProvider router={router}/>
   </div>
  );
}
function Layout() {
  return (
    <div>
      <BasicNav />
      <Footer></Footer>
    </div>
  );
}
function Layout1() {
  return (
    <div>
      <Dashboard></Dashboard>
    </div>
  )
}
function Layout2() {
  return (
    <div>
    <Emp></Emp>
    </div>
  )
}


export default App;
