import React, { useState } from "react";
import { Form, Card, Container, Row, Col, FormLabel, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import EmployeeData from './EmployeeData.json';

function Admin_Work() {
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [work,setWork]=useState('');

    const handleDropdownChange = (event) => {
        setSelectedEmployee(event.target.value);
    };

    const handleSearchClick = () => {
        const selectedEmp = EmployeeData.Employees.find(emp => emp.Id === selectedEmployee);
        setEmployeeDetails(selectedEmp);
    };

    return (
        <div>
            <section className="my-lg-9 my-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-10 offset-lg-1 col-md-12">
                            <div className="card bg-light card-body p-5 text-start">
                                <h3 className="mb-3 d-flex justify-content-center" style={{ fontWeight: 'bold' }}>Assign Task</h3>
                                <Form>
                                    <Form.Group className='mb-3'>

                                    <Form.Label>Work Id:</Form.Label>
                                    <Form.Control className='mb-3' type='text' placeholder='Work Id'></Form.Control>

                                    <Form.Label>Work Title:</Form.Label>
                                    <Form.Control className='mb-3' type='text' placeholder='Work Title' onChange={(e)=>setWork(e.target.value)}></Form.Control>

                                    <Form.Label>Work Description:</Form.Label>
                                    <Form.Control className='mb-3' style={{height:'5rem'}} type='text' placeholder='Description'
                                    />

                                    <Form.Label>Work Image:</Form.Label>
                                    <Form.Control className='mb-3' type='file' placeholder=''></Form.Control>

                                    <Form.Label>Work Start Date:</Form.Label>
                                    <Form.Control className='mb-3' type='date' placeholder=''></Form.Control>

                                    <Form.Label>Work End Date:</Form.Label>
                                    <Form.Control className='mb-3' type='date' placeholder=''></Form.Control>

                                        
                                        <Form.Label>Assigned Employee:</Form.Label>
                                        <Card>
                                            <Container>
                                                <Row>
                                                    <Col md='auto' className='mt-4'>
                                                        <Form.Label>Search Employee:</Form.Label>
                                                    </Col>
                                                    <Col className='mt-3'>
                                                        <Form.Select className='mb-3' value={selectedEmployee} onChange={handleDropdownChange}>
                                                            <option>Select</option>
                                                            {EmployeeData.Employees.map(employee => (
                                                                <option key={employee.Id} value={employee.Id}>{employee.firstName}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col md='auto' className='mt-3'>
                                                        <Button onClick={handleSearchClick}>Search</Button>
                                                    </Col>
                                                </Row>
                                                {employeeDetails && (
                                                    <>
                                                        <Table striped bordered hover className="mt-3">
                                                            <tbody>
                                                                <tr>
																	
																		<td><b>Employee ID</b></td>
																		
																		<td><b>{employeeDetails.Id}</b></td>																	
                                                                </tr>
                                                                <tr>
                                                                    <td>Employee Name</td>
                                                                    <td>{employeeDetails.firstName} {employeeDetails.lastName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Designation</td>
                                                                    <td>{employeeDetails.Designation}</td>
                                                                </tr>
                                                                {/* Add more rows for additional fields */}
                                                            </tbody>
                                                        </Table>
                                                        <Row>
                                                            <Col className='d-flex justify-content-center alignItems-center mb-3'>
															<Button className='me-5' onClick={() => alert(work+"Work Assigned for Employee Id: "+employeeDetails.Id)}>Assign</Button>
                                                                <Button>Cancel</Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Container>
                                        </Card>
                                        <Form.Label className="mt-4">Feedback:</Form.Label>
                                        <Form.Control className='mb-3' type='text' placeholder='Feedback'></Form.Control>
                                    </Form.Group>
                                    <Button variant='success' style={{ width: '100%' }}>Submit</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Admin_Work;