import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { Link, Outlet } from "react-router-dom";


export default function BasicNav() {
 
  return (
    <div>
         {/*  start */}

   <Navbar collapseOnSelect expand="lg" className="shadow"  >
      <Container>
        <Navbar.Brand href="#home">
        <img
              alt=""
              src="../img/newlogo.png"
              width="140"
             
              className="d-inline-block align-top"
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav navbarScroll" />
        <Navbar.Collapse id="responsive-navbar-nav navbarScroll">
          <Nav className="ms-auto me-auto fw-bold fs-6 ">
          <Nav.Link href='#home' className="" as={Link} to="" >Home</Nav.Link>
            <Nav.Link href='#aboutus' className="menuleftmargin" as={Link} to="/aboutus" >About Us</Nav.Link>
            <Nav.Link  href='#service' className="menuleftmargin" as={Link} to="/service">Service</Nav.Link>
            <Nav.Link  href='#career' className="menuleftmargin" as={Link} to="/career">Career</Nav.Link>
           {/* <Nav.Link href='#portfolio'className=""  as={Link} to="/portfolio">Portfolio</Nav.Link> */}
            <Nav.Link href='#contactus' className="menuleftmargin"  as={Link} to="/contactus">Contact us</Nav.Link>
            {/* <Nav.Link href='#softwaredev' className="menuleftmargin"  as={Link} to="/softwaredev">Custom software</Nav.Link> */}
        
          </Nav>
          <Nav>
          <Nav className='ms-auto'>
         <Nav.Link  href='#'><Button variant='dark buttonbg me-2' as={Link} to="/loginform">Login</Button></Nav.Link>
         </Nav> 
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div>
    <Outlet></Outlet>
    </div>
   {/*  end */}
    </div>
    
  )
}
