import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
const Emp_Designation_Info = ()  => {
    const[formData,setFormData]=useState({
        designation:'',
    });
    const handleChange=(e) => {
        const {name,value}=e.target;
        setFormData({
            ...formData,
            [name]:value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            alert('Data Submitted successfully');
        })
        .catch(error => {
            console.error('Error:',error);
        });
    };
  return (
    <div>
    <section className="my-lg-9 my-5">
      <div className="container">
          <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 offset-lg-0 col-md-12">
                    <div className="card bg-light card-body p-5">
                              <h3 className="mb-3 d-flex justify-content-center" style={{fontWeight:'bold'}}>Employee Job Details</h3>
                              <Form onSubmit={handleSubmit}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Designation:</Form.Label>
                                    <Form.Control className='mb-3' type='text' placeholder='job title' name='designation' onChange={handleChange} value={formData.designation}></Form.Control>
                                    <Form.Label>Date of Joining:</Form.Label>
                                    <Form.Control className='mb-3' type='date' placeholder='' name='designation' onChange={handleChange} value={formData.designation}></Form.Control>
                                    <Form.Label>Employee Status:</Form.Label>
                                    <Form.Select className='mb-3'>
                                        <option>Select</option>
                                        <option value='1'>Full Time</option>
                                        <option value='2'>Part Time</option>
                                    </Form.Select>
                                    <Form.Label>Work Hours:</Form.Label>
                                
                                <Form.Group className='mb-3'>
                                <Form.Control type='text' placeholder='Daily Hours' className='mb-3' name='designation' onChange={handleChange} value={formData.designation}></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} className='mb-3'>
                                    <Form.Label>Job Description:</Form.Label>
                                    <Form.Control type='text' placeholder="Job Description" name='designation' onChange={handleChange} value={formData.designation}/>
                                </Form.Group>
                                <Form.Group as={Col} className='mb-3'>
                                    <Form.Label>Previous Designation :</Form.Label>
                                    <Form.Control type='text' placeholder="previous role " name='designation' onChange={handleChange} value={formData.designation} />
                                </Form.Group>
                                
                                </Form.Group>
                                    <Button variant='primary' style={{width:'100%'}}>Submit</Button>
                              
                              </Form>
                        </div>
                </div>
            </div>
        </div>
    </section>
</div>
  )
}

export default Emp_Designation_Info