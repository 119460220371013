import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Card from 'react-bootstrap/Card';
import './Mobile_App_Development.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { CgWebsite } from "react-icons/cg";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { IoNewspaperSharp } from "react-icons/io5";
import { TbDeviceDesktopCode } from "react-icons/tb";
import { MdContentPasteSearch } from "react-icons/md";
import { TbShoppingCartCode } from "react-icons/tb";
import { DiCodeBadge } from "react-icons/di";
import { SiAmazonapigateway } from "react-icons/si";
import { GrHostMaintenance } from "react-icons/gr";


import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
function Web_Development() {
  return (
    <div>

<div className='headerc d-flex align-items-center justify-content-center'>

<Breadcrumb className='custom-breadcrumb'>

    <Breadcrumb.Item>
    <Link  to="/" className="" >Home</Link>

    </Breadcrumb.Item>
   
    <Breadcrumb.Item>
    <Link  to="/web" className="active" >Web Development Service</Link>
    {/* <a as="Link" to="/service" className="active" >Service</a> */}
    
     </Breadcrumb.Item>
    
</Breadcrumb>

</div>
        <Container fluid>
            <div className='webmaindiv m-4'>
                <div className="sdiv1 col-12 col-md-12 ">
                <Row><Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>

                <Card className='main-title-ec-c'>
                    <h1 className="main-title mb-3 mt-4">Web Development Service</h1>
                 </Card>
                 </Col>
                 </Row>
                     <Row>
                          <Col xs={12} md={12} >
                     <div className=''>
                        <Card className='imgcard2 ' style={{width:'60%'}}>
                          <Row>
                             
                              <Col className='d-flex justify-content-center align-Items-center'>
                              <img src="../img/service/DEVELOPMENT.png" className='webmain' height='100%' width='100%'/>
                               </Col>
                              </Row>
                        </Card>
                     </div>
                     </Col>
                     </Row>

                    <hr/>
                  </div>
                    <div className='m-2 sdiv2'>
                        <Row><Col>
                              <h1 className='overview mt-1'>Overview</h1>
                              </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={12} >
                            <div>
                                <p className='otext'>Welcome to our comprehensive guide to web development! Whether you are a 
                                beginner taking your first steps into the world of coding or a seasoned developer looking to
                                 expand your skills, this page will serve as your roadmap to mastering web development
                                 </p>
                            </div>
                            <div>
                              <p className='otext'>In today's digital age, a strong understanding of web development is essential 
                              for anyone looking to build a successful online presence. From creating stunning websites to developing
                               powerful web applications, the possibilities are endless when you have the right knowledge and tools.
                                </p>
                            </div>
                            
                          </Col>
                        </Row>
                    </div>
                    <hr/>
                    <div className='mt-3 webdevdiv'>
                        <Row>
                            <Col xs={12} md={12}>
                            </Col>
                            <h2 className='mt-3 logotextfirst'>Web Development </h2>
                            <p className="desc mt-2">An overview of web development, its importance, and the technologies involved</p>
                        </Row>
                    

                     <Row className='mb-5'>
                        <Col xs={12} md={6} className='d-flex justify-content-center align-Items-center mt-auto mb-auto'>
                        {/* <img className='ec2 mb-3' src="../img/service/MD1.png" height='70%' width='70%'></img> */}
                      <img className='ec2 mb-3' src="../image/WEB_SITE_DEVOLPMENT2.png"   width='50%'></img>
                        
                     </Col>
                        <Col xs={12} md={6} className='' >
                            <p className='otext1 text-dark'>At IRTCM Webservices, we're not just another web development company - we're your partner in making your digital dreams come true. With over 5 years of collective expertise, our full-stack developers are adept at creating sophisticated web solutions tailored to meet your unique needs.
                              <br/><br/>
                              Our unwavering commitment to staying ahead of the curve is what sets us apart. Our team constantly stays abreast of the latest market trends and emerging technologies, ensuring that your project benefits from the most cutting-edge innovations available.
                            <br/><br/>
                            Whether you envision a sleek web application, a dynamic portal, or a user-centric solution, we have the skills and passion to bring your vision to life. Partner with us and let's collaborate to make your next project a success !.</p>
                        </Col>
                   </Row>

                    </div>
                    <div className=' '>
                      <Row>
                        <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                          <p className='web-dev-ser logotextfirst'>Our Web Development Services</p>
                        </Col>
                      </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <CgWebsite style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></CgWebsite>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>Website design</p>                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <TbDeviceDesktopCode style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></TbDeviceDesktopCode>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>Front-end Development</p>
                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <IoNewspaperSharp style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></IoNewspaperSharp>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>Back-end Development</p>
                                  </Col>
                                </Row>
                            </Col>
                          </Row>  

                          <Row>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <MdOutlineScreenshotMonitor style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></MdOutlineScreenshotMonitor>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>Full-stack Development</p>
                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <MdContentPasteSearch style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></MdContentPasteSearch>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>Content Management System (CMS) </p>
                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <TbShoppingCartCode style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></TbShoppingCartCode>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>E-commerce Development</p>
                                  </Col>
                                </Row>
                            </Col>
                          </Row> 
                        
                          <Row>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <DiCodeBadge style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></DiCodeBadge>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>Web Application Development</p>
                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <SiAmazonapigateway style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></SiAmazonapigateway>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>API Integration</p>
                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={4}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <GrHostMaintenance style={{width:'30%',height:'auto',color:'#FF914D'}} className='m-3'></GrHostMaintenance >
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='text-dark fw-bold'>Website Maintenance and Support</p>
                                  </Col>
                                </Row>
                            </Col>
                          </Row>  

                      </div>

                      <div className='mt-5 justify-content-center align-items-center'>
                        <div className=''>
                        <Row><h3 className='head3 logotextfirst'>“ Empower Your Vision, Elevate Your Presence ” </h3></Row><hr/>
                        <Row><Col xs={12} md={6} className='mt-2 d-flex justify-content-center align-items-center'>
                        <img src="../img/b1.png" className='webmain1 m-3'  width='80%'></img>
                          </Col>
                          <Col xs={12} md={6} className='mt-2' >
                          <h3 className='head3 mt-2 logotextsecond'>Why Choose Us for Your Web Development Needs?</h3>
                            <p className='otext1 logotextsecond'>At IRTCM, we understand the pivotal role a well-crafted website plays in your digital strategy. Our team of seasoned professionals brings a wealth of expertise and experience to every project we undertake.


                              <br/><br/>
                              We believe in bespoke solutions tailored to your specific requirements. From conception to launch, we work closely with you, ensuring your vision is translated into a captivating online presence that resonates with your audience.
                            <br/><br/>
                            Transparency and open communication are at the core of our approach. We keep you informed throughout the development process, providing regular updates and insights into our progress.</p>
                            </Col>
                          </Row>
                          </div>
                        
                      </div>
                      
                
                
            </div>
           

        </Container>
    </div>
  )
}

export default Web_Development