import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Card from 'react-bootstrap/Card';
import './Mobile_App_Development.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

// import './Newcss.css';

function Mobile_App_Development() {
  return (
    <div>
            <div className='headerc d-flex align-items-center justify-content-center'>

<Breadcrumb className='custom-breadcrumb'>

    <Breadcrumb.Item>
    <Link  to="/" className="" >Home</Link>

    </Breadcrumb.Item>
   
    <Breadcrumb.Item>
    <Link  to="/mobile" className="active" >Mobile App Development</Link>
    {/* <a as="Link" to="/service" className="active" >Service</a> */}
    
     </Breadcrumb.Item>
    
</Breadcrumb>

</div>     
        <Container fluid className=''>

          
            <div className='m-4'>
                <div className="sdiv1 col-12 col-md-12 ">
                <Row><Col xs={12} md={12} className='d-flex justify-content-center align-Items-center '>
                      <Card className='main-title-ec-c'>
                    <h1 className="main-title mb-2">Mobile App Development Service</h1>
                    <p className="desc mb-0">Unlock unparalleled innovation with our top-tier
                     mobile app solutions,meticulously crafted to exceed expectations..</p>
                     </Card>
                     </Col></Row>
                     <div >
                      <Card className='sidetxtc m-3' style={{width:'18%',height:'auto'}}>
                        <Row>
                          <Col xs={12} md={12}>
                              <p className=' stxt'>Unlock Limitless Possibilities with Our Mobile App Development Solutions.</p>
                          </Col>
                        </Row>
                        </Card>
                     </div>
                     <div >
                      <Card className='sidetxtc1 m-3' style={{width:'18%',height:'auto'}}>
                        <Row>
                          <Col xs={12} md={12}>
                              <p className=' stxt'>We Make Your Vision a Reality. Let's Create Something Extraordinary Together.</p>
                          </Col>
                        </Row>
                        </Card>
                     </div>
                     <Row>
                          <Col xs={12} md={12} >
                     <div className=''>
                        <Card className='imgcard1' >
                          
                              <img src="../img/service/MD1.png" height='100%' width='100%'></img>
                          
                        </Card>
                     </div>
                     </Col>
                     </Row>

                    
                  </div>
                    <div className='m-2 sdiv2'>
                        <Row><Col>
                              <h1 className='overview mt-3 logotextfirst'>Overview</h1>
                              </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={12} >
                            <div>
                                <p className='otext'>In today's modern era, smartphone apps have revolutionized
                                the daily routine, becoming indispensable tools for virtually 
                                every aspect of life. Hyperlink Infosystems as a leading provider 
                                of customized app solutions provides expertise in creating exceptional 
                                mobile applications serving businesses and startups worldwide. We are the leading tailor 
                                made mobile app development company, serving finest apps.
                                 </p>
                            </div>
                            <div>
                              <p className='otext'>Our team consists of elite mobile app developers, well-versed in the latest
                                 technology trends, collaborating with global enterprises to translate innovative
                                  concepts into remarkable mobile applications. With a wealth of experience and 
                                  creativity, we consistently deliver exceptional mobile apps that appeal to 
                                  consumers worldwide.
                                </p>
                            </div>
                            
                          </Col>
                        </Row>
                    </div>
                    <hr/>
                    <div className='mt-3 '>
                        <Card className='bigcard1 ' >
                          <Row>
                          <Col xs={12} md={6} >
                           
                               {/* <Card.Body> */}
                            
                                <Card.Title> <h2 className='mt-3 logotextfirst'> 
                                 {/* <img className='andimg' src="../img/service/MD3.png" height={'auto'} width={'30%'} ></img> */}
                                     Android Development</h2></Card.Title>
                                  <p className='android-text'><br></br>Dominate the Android market with our expert Android app development services.
                                    Our dedicated team of Android developers use the Android SDK, Kotlin and Java to build powerful, scalable and
                                     user-friendly Android apps that deliver superior performance and user engagement.
                                      Whether you're targeting smartphones, tablets or wearable devices, we optimize your Android apps for
                                       maximum visibility, usability and compatibility across a variety of devices and screen sizes.</p>
                               {/* </Card.Body> */}
                             
                           
                            </Col>
                            
                            <Col xs={12} md={6}>
                              <div >         
                                <img className='img-fluid' src="../img/service/MD3.png"  height='24rem' width='50%'/>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                      <hr/>
                      <div className='mt-3'>
                        <Card className='bigcard1' style={{width:'auto',height:'auto'}}>
                          <Row>
                           
                            <Col xs={12} md={6}>
                            <img className='img-fluid' src="../img/service/MD2.png"   width='45%'/>
                              
                            </Col>
                            <Col xs={12} md={6}>
                           
                              
                        

                            <div >   
                                  <Card.Title><h2 className='mt-3 logotextfirst'> 
                                  
                                  {/* <img className='appleimg' src="../img/service/MD2.png" height={'auto'} width={'30%'}></img> */}
                                   Ios Development</h2></Card.Title>
                                      <p className='android-text'><br></br>Reach millions of users worldwide with our professional iOS app development services.
                                        Our team of skilled iOS developers leverage the latest tools and technologies, including Swift and Xcode, to create high-performance,
                                         feature-rich iOS apps that stand out in a crowded app store.
                                        From concept and design to development and deployment, we handle every aspect of the iOS app development process to ensure a seamless and 
                                        successful launch.Specializing in iOS app development, we harness the power of Apple's ecosystem to create seamless, intuitive experiences for 
                                        users. Our dedicated team of iOS experts leverage state-of-the-art tools and technologies to create bespoke applications tailored to your unique needs. 
                                        From concept to launch, we ensure your iOS app stands out in the app store, increasing engagement and increasing brand visibility.</p>      
                              </div>
                          
                           </Col>
                           
                          </Row>
                        </Card>
                      </div>
                      
                      
                      
                
                
            </div>
           

        </Container>
    </div>
  )
}

export default Mobile_App_Development