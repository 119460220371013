
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import './Service.css'
// import Card from 'react-bootstrap/Card';
// import web2 from './web2.jpg'
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { Link } from 'react-router-dom'; 

import '../component/Service.css';
import React from 'react'
import Container from 'react-bootstrap/Container';
//import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { CgArrowRightO } from "react-icons/cg";
function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}
function Service() {
  return (
  
      <Container fluid>
          <div >
          <div className='headerc d-flex align-items-center justify-content-center'>

<Breadcrumb className='custom-breadcrumb'>

    <Breadcrumb.Item>
    <Link  to="/" className="" >Home</Link>

    </Breadcrumb.Item>
   
    <Breadcrumb.Item>
    <Link  to="/service" className="active" >Service</Link>
    {/* <a as="Link" to="/service" className="active" >Service</a> */}
    
     </Breadcrumb.Item>
    
</Breadcrumb>

</div>


          <div className=" container-fluid" >
        <section id="contact" className="">
    <div className="container">
      <div className="row">
        
      <div className="col-md-12 text-center one">
    
      <h3 className="logotextfirst">Services</h3>
         
      </div>
        <div className="col-md-12">
          
          <p>Ready to embrace the digital transformation journey with IRTCM INDIA PVT LTD. Reach out to us today to discuss your business needs and explore how we can help you achieve your goals.</p>
        </div>
       
      </div>
    </div>
  </section>
          {/* <div className="container ">
            <div className="row ">
              <div className="col-md-12 tcw  text-center">
                <h1 className="text-center fs-1" style={{'margin': '100px'}}>
                  {" "}
                  IT & Software Design, Management & Support Services
                </h1>
              </div>
            </div>
          </div> */}
        </div>

        <div className="  container mt-2" >
          <div className="row">
            <div className="col-md-4 flex-column">
              <div className="service card shadow ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../image/mobileapp.png"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                    
                  />
                </div>
                <div className="card-body" >
                  <h4 className="card-title fs-5 text-b servicetext2">
                    {" "}
                    Mobile App Development
                  </h4>
                  <p className="card-text">
                    {" "}
                    Transform your ideas into a mobile application through
                    IRTCM India. The mobile application industry is undergoing a
                    vast transformative phase. With the advancement of
                    micro-processing technology.
                  </p>
               
               <Link  to="/mobile" onClick={scrollToTop}> <CgArrowRightO size={30}  color="#FF914D" /></Link>  
               </div>  
              </div>
            </div>
            <div className="col-md-4 flex-column">
              <div className="service card shadow ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../image/SOFTWARE_DEVELOPMENT.png"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2" >
                    {" "}
                    Software Development
                  </h4>
                  <p className="card-text">
                    {" "}
                    Promising a Compatible Partner! In IRTCM India,
                    software creation and innovation are enabling companies,
                    both small and large, to achieve high levels of performance.
                  </p>
                  <Link to="/customsoftware" onClick={scrollToTop}> <CgArrowRightO size={30} color="#FF914D" /></Link>  
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-column">
              <div className="service card shadow ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../image/digital_media.png"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2"> Digital Media</h4>
                  <p className="card-text">
                    {" "}
                    Broaden the digital footprint of your business! How about
                    taking a step forward. Our services come under four headings
                    – Marketing Contact, Digital Presence, Sales Enablement &
                    Marketing Technology.
                  </p>
                  <Link to="/digital" onClick={scrollToTop}> <CgArrowRightO size={30} color="#FF914D" /></Link>  
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-4 flex-column">
              <div className="service card shadow ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../image/WEB_SITE_DEVOLPMENT.png"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2">Web Development</h4>
                  <p className="card-text">
                    {" "}
                    Our Web designers help to metamorphose your brand into a
                    visual story. We help to turn your website into an effective
                    way to engage with your audience and a high-performing
                    marketing tool for your business.
                  </p>
                  <Link to="/web" onClick={scrollToTop}> <CgArrowRightO size={30}  color="#FF914D"  /></Link>  
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-column">
              <div className="service card shadow ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../image/IT_CONSULTANT.png"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2"> IT Consultancy</h4>
                  <p className="card-text">
                    {" "}
                    Still handling IT on your own? You’ve got better things to
                    do than figure out the right tech support for your business.
                    Let IRTCM India handle getting you the top-notch
                    solution you deserve with our network of partners and
                    products.
                  </p>
                  <Link to="/contactus" onClick={scrollToTop}> <CgArrowRightO size={30} color="#FF914D" /></Link>  
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-column">
              <div className="service card shadow ms-1 me-1 text-center">
                <div className="text-center mt-1">
                  <img
                    src="../image/E-COMMERCE.png"
                    width="172"
                    height="172"
                    className=""
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <h4 className="card-title fs-5 text-b servicetext2">
                    E-Commerce Application
                  </h4>
                  <p className="card-text">
                    As one of the best e-commerce application development
                    companies, IRTCM India have built numerous on-demand
                    e-commerce applications to mobilize your business. Our
                    E-Commerce applications are highly competent to deliver
                    immediate needs of the market landscape and get aligned to
                    every process digitally.
                  </p>
                  <Link to="/ecommerce" onClick={scrollToTop}> <CgArrowRightO size={30} color="#FF914D"   /></Link>  
                </div>
              </div>
            </div>
          </div>
        
        
      </div>












         

    




    
        {/* <h1 className='ht fs-1 mt-3'>Software Development Services</h1>
        </div>
        <div className='m-4 div1'>
            <Card className='mainCard1' style={{width:'auto',height:'auto'}}>
          <Row>
          <Col xs={12} md={6}>
          <div className='c1'>
          <Card className='card1 m-3' style={{width:'auto',height:'24rem'}}>
          <Card.Body>
         
            <Card.Title><h3>Web Development</h3></Card.Title>
              <h6 ><br></br>IRTCM specializes in custom web app development, aiding businesses of all sizes in
                    achieving their objectives. Our adept developers craft user-centric interfaces, responsive
                      designs, and adaptable architectures tailored to your specific needs. We excel in backend,
                      frontend, API integration, and performance optimization, guaranteeing top quality through
                        rigorous testing. With comprehensive end-to-end support, IRTCM Web Apps elevate your online presence, helping you reach your goals effectively.</h6>
           </Card.Body>
        </Card>
        </div>
        </Col>
        <Col xs={12} md={6}>
        <div className='c2'>         
        <img className='img-fluid' src={web2}  height='23rem' width='90%'/>
        </div>
        </Col>
        </Row>
        </Card>
          
        </div>
        <div className='m-4 div1'>
        <Card className='mainCard1'>
        <Row>
        <Col xs={12} md={6}>
          <div className='c3'>
          <img src='./img/mobile.jpg' className='cardimg' style={{width:'100%',height:'23rem'}}></img>
        </div>
        </Col>
   
          <Col xs={12} md={6}>
          <div className='c4'>
          <Card className='card4 m-3' style={{width:'auto',height:'24rem'}}>
          <Card.Body>
          <Card.Title><h3>Mobile Development</h3></Card.Title>
            <h6><br></br>Specializing in mobile app development, we create native and hybrid
            apps tailored to various platforms, ensuring responsiveness, abundant features,
              and top-notch security. Our services extend beyond app creation, encompassing
              API integration, performance optimization, rigorous testing, and efficient debugging.
                With our support, your app is prepared for a successful launch on app stores, enabling
                you to elevate engagement, expand reach, and achieve your goals effectively..</h6>
          </Card.Body>
          </Card>
          </div>
          </Col>
          </Row>
          </Card>
          </div>
      <div className='m-4 div1'>
      <Card className='mainCard1'>
      <Row>
      <Col xs={12} md={6}>
      <div className='c1'>
      <Card className='card1 m-3' style={{width:'auto',height:'24rem'}}>
      <Card.Body>
      <Card.Title><h3>UX/UI design</h3></Card.Title>
        <h6><br></br>We provide comprehensive UX/UI services to create user-friendly, visually
         appealing, and engaging products. Our dedicated team collaborates with you throughout the
          entire process, ensuring your product meets and exceeds your expectations. From ideation to
           realization, we meticulously craft your creation to perfectly align with your needs, delivering
            an exceptional offering that surpasses even your loftiest expectations.</h6>
      </Card.Body>
      </Card>
      </div>
      </Col>
 
      <Col xs={12} md={6}>
      <div className='c2'>
      <img src='./img/web.jpg' className='cardimg' style={{width:'100%',height:'22rem'}}></img>
      </div>
      </Col>
      </Row>
      </Card>
      </div>
      <div className='m-4 div1'>
      <Card className='mainCard1'>
      <Row>
      <Col xs={12} md={6}>
        <div className='c2'>
        <img className='mt-4 cardimg' src='./img/marketing.jpg' style={{width:'100%',height:'23rem'}}></img>
        </div>
        </Col>
        <Col xs={12} md={6}>
            <div className='c1'>
            <Card className='card4 m-3' style={{width:'auto',height:'24rem'}}>
            <Card.Body>
            <Card.Title><h3>Marketing</h3></Card.Title>
              <h6><br></br>  Unlock your business potential with innovative marketing solutions 
              from IRTCM Consulting. From tailored strategies to dynamic digital campaigns, we 
              enhance your brand presence and increase engagement. Elevate your marketing game with us -
              where strategy succeedsAt IRTCM Consulting, effective marketing for business growth is our focus.
                With tailored services and multi-channel campaigns, we exceed expectations. Partner with us to
                achieve your marketing goals with precision and impact
               </h6>
        </Card.Body>
        </Card>
        </div>
        </Col>
        </Row>
        </Card> */}
        </div>
     
      </Container>
      
  );
}

export default Service;