import React from 'react'
import { Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';

const Emp_Address_Info = () => {
  const [formData, setFormData] = useState({
    address: '',
    city: '',
    district: '',
    state: '',
    country: '',
    taluka:'',
    pincode:''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Send POST request to the backend
    fetch('http://192.168.0.119:8585/addEmployeeAddress', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      alert('Data submitted successfully');
      setFormData({
        address: '',
        city: '',
        district: '',
        state: '',
        country: '',
        taluka:'',
        pincode:''
      });
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div>
    <section className="my-lg-9 my-5 ">
      <div className="container">
          <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 offset-lg-1 col-md-12">
                    <div className="card text-start bg-light card-body p-5">
                              <h3 className="mb-3 d-flex justify-content-center" style={{fontWeight:'bold'}}>Employee Address </h3>
                              <Form  onClick={handleSubmit}>
                                <Form.Group className='mb-3'>
                                    
                                    <Row>
                                    <Form.Group as={Col} xs={12} md={12}  className='mb-3'>
                                      <Form.Label> Address:</Form.Label>
                                      <Form.Control type='text' placeholder='Enter Address' name='address' value={formData.address}
                        onChange={handleChange}></Form.Control>
                                    </Form.Group>
                                  
                                    </Row>
                                    <Row>
                                    <Form.Group className='mb-3' as={Col} xs={6} md={6}>
                                      <Form.Label>City:</Form.Label>
                                      <Form.Control type='text' placeholder='Enter City' name='city' value={formData.city}
                        onChange={handleChange}></Form.Control>
                                    </Form.Group>
                                      <Form.Group className='mb-3' as={Col} xs={6} md={6}>
                                        <Form.Label>District:</Form.Label>
                                        <Form.Control type='text' placeholder='Enter District' name='district' value={formData.district}
                        onChange={handleChange}></Form.Control>
                                      </Form.Group>
                                     
                                    </Row>

                                    <Row>
                                    <Form.Group className='mb-3' as={Col} xs={6} md={6}>
                                      <Form.Label>State:</Form.Label>
                                      <Form.Control type='text' placeholder='Enter State' name='state' value={formData.state}
                        onChange={handleChange}></Form.Control>
                                    </Form.Group>
                                      <Form.Group className='mb-3' as={Col} xs={6} md={6}>
                                        <Form.Label>Country:</Form.Label>
                                        <Form.Control type='text' placeholder='Enter Country' name='country' value={formData.country}
                        onChange={handleChange}></Form.Control>
                                      </Form.Group>
                                     
                                    </Row>
                                    <Row>
                                    <Form.Group className='mb-3' as={Col} xs={6} md={6}>
                                      <Form.Label>Taluka:</Form.Label>
                                      <Form.Control type='text' placeholder='Enter Taluka' name='taluka' value={formData.taluka}
                        onChange={handleChange}></Form.Control>
                                    </Form.Group>
                                    <Form.Group className='mb-3' as={Col} xs={6} md={6}>
                                      <Form.Label>Pincode:</Form.Label>
                                      <Form.Control type='number' placeholder='Enter Pincode' name='pincode' value={formData.pincode}
                        onChange={handleChange}></Form.Control>
                                    </Form.Group>
                                      
                                     
                                    </Row>
                                    
                                    </Form.Group>                                
                                  
                                    <Button variant='primary' style={{width:'100%'}}>Submit</Button>
                              </Form>
                        </div>
                </div>
            </div>
        </div>
    </section>
</div>
  )
}

export default Emp_Address_Info;