import React from 'react'
import { Row,Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
export default function Portfolio() {
  return (
    <div>
         <Row xs={1} md={3} className="g-4 justify-content-center">
      {Array.from({ length: 1}).map((_, idx) => (
        <Col key={idx}>
          <Card style={{ width: '18rem' }} className='mt-2'>
            <Card.Img variant="top" src="./img/portfolio.jpg"/>
            <Card.Body>
              <Card.Title>Our Portfolio</Card.Title>
              <Card.Text>
           Coming Soon.....
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>    </div>
  )
}
