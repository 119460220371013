import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Update_Leave_Approval() {
  return (
    <div>
    <section className="my-lg-9 my-5 ">
      <div className="container">
          <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 offset-lg-0 col-md-12">
                    <div className="card bg-light card-body p-5">
                              <h3 className="mb-4 d-flex justify-content-center" style={{fontWeight:'bold'}}>Update Leave Approval</h3>
                              <Form>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Update Status:</Form.Label><br></br>
                                    <select style={{width:"50%"}} className='mb-3'>
                                        <option>select</option>
                                        <option value='1'>Pending</option>
                                        <option value='2'>Approved</option>
                                        <option value='1'>Rejected</option>
                                    </select> <br></br>                                  
                                    <Form.Label>Total Leave Days:</Form.Label>
                                    <Form.Control type='number' placeholder="" />
                                    <Form.Label>Notes:</Form.Label>
                                    <Form.Control type='number' placeholder="-----" />
                                    </Form.Group>
                                    <Button variant='primary' style={{width:'100%'}}>Submit</Button>
                              </Form>
                        </div>
                </div>
            </div>
        </div>
    </section>
</div>
  )
}

export default Update_Leave_Approval