import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Emp_Register() {
  return (
    <div className='P1'>
    <section className="my-lg-9 my-5 ">
      <div className="container">
          <div className="row-lg-12 offset-lg-1 col-md-10 justify-content-right align-items-right">
              <div className="col-lg-12 offset-lg-0 col-md-12 justify-content-right align-items-right">
                    <div className="card w-76 bg-light card-body p-5">
                              <h3 className="mb-3 d-flex justify-content-center" style={{fontWeight:'bold'}}>Sign Up</h3>
                              <Form>
                                <Form.Group className='mb-3'>
                                <Form.Label>UserName:</Form.Label>
                                    <Form.Control className='mb-3' type='text' placeholder="Enter User Name" />
                                    <Row>
                                <Form.Group as={Col} className='mb-3'>
                                    <Form.Label>Email Id:</Form.Label>
                                    <Form.Control type='text' placeholder="Enter Email" />
                                </Form.Group>
                                </Row>
                                <Row>
                                <Form.Group as={Col} className='mb-3'>
                                    <Form.Label>Mobile Number:</Form.Label>
                                    <Form.Control type='text' placeholder="Enter Mobile Number" />
                                </Form.Group>
                                </Row>
                                <Row>
                                <Form.Group as={Col} className='mb-3'>
                                    <Form.Label>Password:</Form.Label>
                                    <Form.Control type='password' placeholder="Enter your password" />
                                </Form.Group>
                                </Row>
                                <Row>
                                <Form.Group as={Col} className='mb-3'>
                                    <Form.Label>Role:</Form.Label>
                                    <Form.Select className='mb-3'>
                                        <option>Select</option>
                                        <option value='1'>Super Admin</option>
                                        <option value='2'>Admin</option>
                                        <option value='3'>Normal</option>
                                    </Form.Select>
                                </Form.Group>
                                </Row>
                                    </Form.Group>
                                    <Button variant='primary' style={{width:'100%'}}>Register</Button>
                              </Form>
                        </div>
                </div>
            </div>
        </div>
    </section>
</div>

  )
}
