import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Apply_Leave() {
  return (
    <div className='P1'>
  <section className="my-lg-9 my-5 ">
    <div className="container">
        <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 offset-lg-0 col-md-12 ">
              
                        <div className="card bg-light card-body p-5">
                            
                                  <h3 className="mb-3 d-flex justify-content-center" style={{fontWeight:'bold'}}>Post Leave</h3>
                                  <Form>
                                    <Form.Group className="mb-3">
                                      <Form.Label><b>Date Range</b></Form.Label>
                                      <Row>
                                      <Form.Group as={Col} className="mb-3">
                                      <Form.Label>From Date:</Form.Label>
                                      <Form.Control type="date" placeholder="From"/>
                                      </Form.Group>
                                      <Form.Group as={Col} className="mb-3">
                                      <Form.Label>To Date:</Form.Label>
                                      <Form.Control className='' type="date" placeholder="To" />
                                      </Form.Group>
                                      </Row>
                                      <Form.Label>Leave Type:</Form.Label>
                                      <Form.Select className='mb-3'>
                                        <option>Select</option>
                                        <option value='1'>Sick Leave</option>
                                        <option value='2'>Military Leave</option>
                                        <option value='3'>Maternity/Paternity Leave</option> 
                                        <option value='4'>Annual Leave/Vacation Leave</option>
                                        <option value='5'>Family/Medical Leave</option>
                                        <option value='6'>Bereavement Leave</option>
                                        <option value='7'>Jury Duty Leave</option>
                                        <option value='8'>Unpaid Leave</option>
                                        <option value='9'>Parental Leave</option>
                                      </Form.Select>
                                      <Form.Label>Reason:</Form.Label>
                                      <Form.Control className='mb-3' type="text" placeholder=" " />
                                      <Form.Label><b>Contact Info During Leave</b></Form.Label>
                                      <Row>
                                      <Form.Group as={Col} className="mb-3">
                                      <Form.Label>Phone Number:</Form.Label>
                                      <Form.Control type="text" placeholder="Phone Number" />
                                      </Form.Group>
                                      <Form.Group as={Col} className="mb-3">
                                      <Form.Label>Email Id:</Form.Label>
                                      <Form.Control type="email" placeholder="Enter Email" />
                                      </Form.Group>
                                      <Form.Group as={Col} className="mb-3">
                                      <Form.Label>Address(if different):</Form.Label>
                                      <Form.Control type="text" placeholder="Address" />
                                      </Form.Group>
                                      </Row>
                                    </Form.Group>
                                    <Button variant='primary' style={{width:'100%'}}>Submit Leave Request</Button>
                                  </Form>
                            
                          </div>
                  
              </div>
          </div>
      </div>
  </section>
  </div>
  )
}

export default Apply_Leave