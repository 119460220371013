import React from 'react'
import { Row, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

function Salary_Slip() {
  return (
    <div>
    <section className="my-lg-9 my-5 ">
      <div className="container">
          <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 offset-lg-0 col-md-12">
                    <div className="card bg-light card-body p-5">
                            <h3 className="mb-3 d-flex justify-content-center" style={{fontWeight:'bold'}}>Employee Payslip </h3>
                              <Form>
                                <div>
                                  <Form.Group className='mb-3' as={Col}>
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control style={{width:'50%',height:'50%'}} className='mb-2' type='text' placeholder='Company Name*'></Form.Control>
                                        <Form.Label>Company Address</Form.Label>
                                        <Form.Control style={{width:'50%',height:'1.8rem'}} className='mb-2' type='text' placeholder='Company Address'></Form.Control>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control style={{width:'50%',height:'1.8rem'}} className='mb-2' type='text' placeholder='City, pincode'></Form.Control>
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control style={{width:'50%',height:'1.8rem'}} className='mb-2' type='text' value='India'></Form.Control>
                                      </Form.Group>
                                </div><br></br>
                                <div className='d-flex'>
                                <Form.Group className='mb-3'>
                                  <Form.Label className='mb-3'><b>Employee Pay Summary</b></Form.Label>
                                  
                                  <Table>
                                   <Row>
                                    <Form.Group  as={Col} className='mb-3'>
                                    <Row>
                                      <Form.Group  as={Col} className='mb-3'>
                                        <Row className=''>
                                          <Form.Group  as={Col} className='mb-2 mt-2'>
                                            <Form.Label>Employee Name:</Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Control style={{height:'95%'}} className='' type='text' placeholder='Eg:Nikita Ghodake'></Form.Control>
                                          </Form.Group>
                                      </Row>
                                      <Row className=''>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Label>Pay Period: </Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Control type='date' style={{height:'95%'}} placeholder=''></Form.Control>
                                          </Form.Group>
                                      </Row>
                                      <Row className=''>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Label>Loss of Pay Days: </Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Control type='number' style={{height:'95%'}} placeholder='0'></Form.Control>
                                          </Form.Group>
                                      </Row>
                                    </Form.Group>
                                    <Form.Group  as={Col} className='mb-3'>
                                    <Row className=''>
                                        <Form.Group as={Col} className='mb-2 mt-2'>
                                        <Form.Label>Employee Id:</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} className='mb-2 mt-2'>
                                          <Form.Control type='text' style={{height:'95%'}}   placeholder='Eg:1001'></Form.Control>
                                        </Form.Group>
                                      </Row>

                                      <Row className=''>
                                        <Form.Group as={Col} className='mb-2 mt-2'>
                                        <Form.Label>Pay Days: </Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} className='mb-2 mt-2'>
                                          <Form.Control type='number' style={{height:'95%'}}   placeholder='Eg:22'></Form.Control>
                                        </Form.Group>
                                      </Row>
                                      <Row className=''>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Label>Pay Date: </Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Control type='date' style={{height:'95%'}}  placeholder=''></Form.Control>
                                          </Form.Group>
                                      </Row>

                                      
                                    </Form.Group>
                                   </Row>
                                  </Form.Group>
                                 </Row>
                                 </Table>
                                </Form.Group>
                                </div>                          
                                  
                                <div>
                                <Form.Group className='mb-3'>
                                      <Form.Label className='mb-3'><b>Income Details</b></Form.Label>
                                      <Table>
                                <Row className=''>
                                    <Form.Group  as={Col} className='mb-3'>
                                    <Row className=''>
                                      <Form.Group  as={Col} className='mb-3'>
                                        <Row className=''>
                                          <Form.Group  as={Col} className='mb-2 mt-2'>
                                           <th> <Form.Label>Earning</Form.Label></th>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                         <th> <Form.Label>Amount</Form.Label></th>
                                          </Form.Group>
                                      </Row>
                                      <Row className=''>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Label>Basic: </Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Control type='number' style={{height:'95%'}} placeholder='0'></Form.Control>
                                          </Form.Group>
                                      </Row>
                                      <Row className=''>  
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Label>HRA: </Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Control type='number' style={{height:'95%'}} placeholder='0'></Form.Control>
                                          </Form.Group>
                                      </Row>
                                      <Row className=''>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Label>Gross Earning: </Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            {/* <Form.Control placeholder='0'></Form.Control> */}

                                          </Form.Group>
                                      </Row>
                                    </Form.Group>

                                    <Form.Group  as={Col} className='mb-3'>
                                    <Row className=''>
                                        <Form.Group as={Col} className='mb-2 mt-2'>
                                        <th><Form.Label>Deduction </Form.Label></th>
                                        </Form.Group>
                                        <Form.Group as={Col} className='mb-2 mt-2'>
                                        <th><Form.Label>Amount </Form.Label></th>                                      
                                        </Form.Group>
                                      </Row>

                                      <Row className=''>
                                        <Form.Group as={Col} className='mb-2 mt-2'>
                                        <Form.Label>Income Tax: </Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} className='mb-2 mt-2'>
                                          <Form.Control type='number' style={{height:'95%'}} placeholder='0'></Form.Control>
                                        </Form.Group>
                                      </Row>
                                      <Row className=''>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Label>Provident Fund </Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Control type='number' style={{height:'95%'}} placeholder='0'></Form.Control>
                                          </Form.Group>
                                          
                                      </Row>
                                      <Row className=''>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Label>Total Deductions</Form.Label>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            {/* <Form.Control type='date' placeholder='0'></Form.Control> */}

                                          </Form.Group>
                                          
                                      </Row>

                                    </Form.Group>
                                   </Row>
                                  </Form.Group>
                                 </Row>
                                 </Table>
                                </Form.Group>
                                </div>

                                <div className='mb-3'>
                                      <Row className='border'>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                              <Form.Label>Total Net Payable</Form.Label><br/>
                                              <Form.Text className='text-muted'>Gross Earning - Total Deductions</Form.Text>
                                          </Form.Group>
                                          <Form.Group as={Col} className='mb-2 mt-2'>
                                            <Form.Control type='text' placeholder='Rs 0'></Form.Control>
                                          </Form.Group>
                                      </Row>
                                </div>                          
                                  
                                    <Button variant='success' style={{width:'100%'}}>Generate Payslip</Button>
                              </Form>
                        </div>
                         <div className="card bg-smoke card-body p-3 w-75 mt-5 float-md-left shadow-sm bg-white rounded">
                            <h3 className="mb-3 d-flex justify-content-center" style={{fontWeight:'bold'}}>Employee Payslip sample 2 </h3>

                            <Form>
                                <div>
                                  <Form.Group className='mb-3' as={Col}>
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control style={{width:'50%',height:''}} className='mb-2' type='text' placeholder='Company Name*'></Form.Control>
                                        <Form.Label>Company Address</Form.Label>
                                        <Form.Control style={{width:'50%',height:'1.8rem'}} className='mb-2' type='text' placeholder='Company Address'></Form.Control>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control style={{width:'50%',height:'1.8rem'}} className='mb-2' type='text' placeholder='City, pincode'></Form.Control>
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control style={{width:'50%',height:'1.8rem'}} className='mb-2' type='text' value='India'></Form.Control>
                                      </Form.Group>
                                </div><br></br>

                                <Form.Group className='mb-3'>
                                  <Form.Label className='mb-3'><b>Employee Pay Summary</b></Form.Label>

                                   <Row className='mb-2'>
                                    <Col>
                                        <Col>
                                              <Form.Label>Employee Name:</Form.Label>
                                        </Col>
                                        <Col>
                                           <Form.Control style={{height:'95%'}} className='' type='text' placeholder='Eg:Nikita Ghodake'></Form.Control>
                                        </Col>

                                    </Col>
                                    <Col>
                                        <Col>
                                              <Form.Label>Employee Id:</Form.Label>
                                        </Col>
                                        <Col>
                                                <Form.Control type='text' style={{height:'95%'}}   placeholder='Eg:1001'></Form.Control>
                                        </Col>
                                    </Col>
                                   </Row>   
                                   <Row className='mb-2'>
                                    <Col>
                                        <Col>
                                        <Form.Label>Pay Period: </Form.Label>
                                        <Col>
                                        <Form.Control type='date' style={{height:'95%'}} placeholder=''></Form.Control>                                       
                                         </Col>
                                        </Col>

                                    </Col>
                                    <Col>
                                        <Col>
                                               <Form.Label>Pay Days: </Form.Label>
                                        <Col>
                                               <Form.Control type='number' style={{height:'95%'}}   placeholder='Eg:22'></Form.Control>                                        
                                               </Col>
                                        </Col>
                                    </Col>
                                   </Row>  
                                   <Row className='mb-2'>
                                    <Col>
                                        <Col>
                                        <Form.Label>Loss of Pay Days: </Form.Label>
                                        </Col>
                                        <Col>
                                        <Form.Control type='text' style={{height:'95%'}} placeholder='0'></Form.Control>                                        

                                        </Col>

                                    </Col>
                                    <Col>
                                        <Col>
                                        <Form.Label>Pay Date: </Form.Label>
                                            <Col>
                                            <Form.Control type='date' style={{height:'95%'}}  placeholder=''></Form.Control>                                        
                                               </Col>
                                        </Col>
                                    </Col>
                                   </Row>      

                                </Form.Group>

                                <Form.Group>
                                  <Form.Label className='mb-3'><b>Income Details</b></Form.Label>
                                  <Row className='mt-2 mb-2'>
                                        <Col><Row className='border'>
                                              <Col>
                                                    <th><Form.Label>Earning</Form.Label></th>
                                              </Col>
                                              <Col>
                                                    <th><Form.Label>Amount</Form.Label></th>
                                              </Col>
                                              </Row>
                                        </Col>
                                        <Col><Row className='border'>
                                              <Col>
                                                  <th><Form.Label>Deductions</Form.Label></th>
                                              </Col>
                                              <Col>
                                                  <th><Form.Label>Amount</Form.Label></th>
                                              </Col>
                                              </Row>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col><Row className='mt-2 mb-2'>
                                              <Col>
                                                  <Form.Label>Basic:</Form.Label>
                                              </Col>
                                              <Col>
                                                   <Form.Control type='text' style={{height:'95%'}}  placeholder='0'></Form.Control>                                        
                                              </Col></Row>
                                        </Col>
                                        <Col><Row className='mt-2 mb-2'>
                                              <Col>
                                                  <Form.Label>Income Tax:</Form.Label>
                                              </Col>
                                              <Col>
                                              <Form.Control type='text' style={{height:'95%'}}  placeholder='0'></Form.Control>                                        
                                              </Col></Row>
                                        </Col>
                                  </Row>
                                  <Row>
                                      <Col>
                                          <Row className='mt-2 mb-2'>
                                            <Col>
                                                  <Form.Label>HRA:</Form.Label>
                                            </Col>  
                                            <Col>
                                            <Form.Control type='text' style={{height:'95%'}}  placeholder='0'></Form.Control>
                                            </Col></Row>
                                      </Col>
                                      <Col>
                                            <Row className='mt-2 mb-2'> 
                                              <Col>
                                                    <Form.Label>PF:</Form.Label>
                                              </Col>
                                              <Col>
                                              <Form.Control type='text' style={{height:'95%'}}  placeholder='0'></Form.Control>
                                              </Col>
                                            </Row>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col>
                                      <Row className='mt-2 mb-2'>
                                            <Col>
                                                  <Form.Label>Gross Earning:</Form.Label>
                                            </Col>
                                            <Col>
                                                   <Form.Control type='text' style={{height:'95%'}}  placeholder='0'></Form.Control>
                                            </Col>
                                      </Row>
                                                    
                                      </Col>
                                       <Col>
                                            <Row className='mt-2 mb-2'>
                                                <Col>
                                                      <Form.Label>Total Deductions:</Form.Label>
                                                </Col>
                                                <Col>
                                                      <Form.Control type='text' style={{height:'95%'}}  placeholder='0'></Form.Control>
                                                </Col>
                                            </Row>
                                      </Col>
                                  </Row>
                                </Form.Group>
                                <Form.Group className='mt-3'>
                                  <Row>
                                    <Col>
                                    <Form.Label>Total Net Pay:</Form.Label><br/><Form.Text>Gross-Earning - Total Deductions</Form.Text>
                                    </Col>
                                    <Col>
                                    <Form.Control type='text' placeholder='RS.0' style={{height:'70%'}}></Form.Control>
                                    </Col>
                                  </Row>
                                </Form.Group>
                                <Button href="http://localhost:3000//home/sanketjalindargaware/React-workspace/wpm/src/Component/Salary_Slip"
                                  target="_blank"
                                  download className='primary mt-3' style={{width:'100%',fontWeight:'bold'}}>Generate Payslip</Button>

                            </Form>
                            

                         </div>
                </div>
            </div>
        </div>
    </section>
</div>
    )
}

export default Salary_Slip