import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function Add_Emp() {
  return (
   
  <div className='P1'>
  <section className="my-lg-9 my-5 ">
    <div className="container">
        <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 offset-lg-0 col-md-12 ">
               
                        <div className="card bg-light shadow">
                            <div className="card-body p-5">
                                  <h3 className="mb-3 d-flex justify-content-center" style={{fontWeight:'bold'}}>Add Employee Details</h3>
                                  <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                      <Form.Label>Name:</Form.Label>
                                      <Form.Control type="text" placeholder="Enter Name" />
                                      <Form.Label>ID No:</Form.Label>
                                      <Form.Control type="text" placeholder="Enter Id No" />
                                      <Form.Label>Email address:</Form.Label>
                                      <Form.Control type="email" placeholder="Enter email" />
                                      <Form.Label>Password:</Form.Label>
                                      <Form.Control type="password" placeholder="Enter Password" />
                                      <Form.Label>Mobile Number:</Form.Label>
                                      <Form.Control type="text" placeholder="Enter Mobile Number" />
                                      <Form.Label>Address:</Form.Label>
                                      <Form.Control type="text" placeholder="Enter Address" />
                                      <Form.Label>Designation:</Form.Label>
                                      <Form.Control type="text" placeholder="Enter Designation" />
                                      <Form.Label>Salary:</Form.Label>
                                      <Form.Control type="number" placeholder="Enter Salary" />
                                      <Form.Label>DOJ:</Form.Label>
                                      <Form.Control type="date" placeholder="Enter Date of Joining" />
                                    </Form.Group>
                                    <Button variant='success' style={{width:'100%'}}>Add</Button>
                                  </Form>
                            </div>
                          </div>
                    
              </div>
          </div>
      </div>
  </section>

  </div>
  )
}

export default Add_Emp