import React from 'react'
import { Row,Col } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {Container,Form,Button,Card} from 'react-bootstrap';
import './ContactUs.css';
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
function ContactUs() {
  return (
    <div>
           <div className='headerc d-flex align-items-center justify-content-center'>

<Breadcrumb className='custom-breadcrumb'>

    <Breadcrumb.Item>
    <Link  to="/" className="" >Home</Link>

    </Breadcrumb.Item>
   
    <Breadcrumb.Item>
    <Link  to="/contactus" className="active" >Contact Us</Link>
    {/* <a as="Link" to="/service" className="active" >Service</a> */}
    
     </Breadcrumb.Item>
    
</Breadcrumb>

</div>
    <Container>
    <h3 className="logotextfirst">Contact Us</h3>
    <h5 className='con-h5 logotextfirst'>We're Here For You !</h5>
            <Card className='con-card'>
              <Row>
              <Col xs={12} md={6}>
                
              <Col  className='text-center' md={4}>
            
            <div className='justify-content-center'>
            <Card className='border-light shadow'  style={{width:'20rem'}}>
              <Card.Title>   <span className='con-icon1'><MdEmail size={30} className='con-icon mt-2'/></span>
              <span className='con-icon1'> <b>Mail  
                </b></span></Card.Title>
                <Card.Body className='text-wrap '>
               <span><a href='mailto:info@irtcmindia.com' className='textdecoration logotextsecond'> info@irtcmindia.com<br/></a></span>
            <span className='con-icon1'> <a className='textdecoration logotextsecond call2' href='mailto:career@irtcmindia.com'> career@irtcmindia.com<br/></a></span>
            <span className='con-icon1'> <a className=' textdecoration logotextsecond call2' href='mailto:support@irtcmindia.com'> support@irtcmindia.com</a></span>
          </Card.Body>
            </Card>

            </div>

            </Col>
            
            <Col  md={4}>
            <div className='justify-content-center'>
               
                
                 <Card className='mt-2 border-light shadow' style={{width:'20rem'}}>
                  <Card.Title><span className='con-icon1'><IoCall size={30} className='con-icon mt-2'/></span> 
               <span className='con-icon1 '> <b>Call </b> </span></Card.Title>  
                <Card.Body>
            <div className='mt-0'>
                                        <p className="mb-0 "><a  href="tel:+917276758721 " className='textdecoration logotextsecond'><span className=' '>+91 7276758721</span></a></p>
                                         
                                    </div>
        
                </Card.Body>
                 </Card>
            </div>
            </Col>
            
            
            <Col  md={4}>
            <div className='justify-content-center mt-2'>
              <Card className='mt-2 border-light shadow' style={{width:'20rem'}}>
           <Card.Title> <span className='con-icon1'> <FaLocationDot size={28} className='con-icon mt-2 '/></span>
             <span className='con-icon1'><b>Address  </b></span>
             </Card.Title>
         <Card.Body  >  <span className='logotextsecond'>Sr.No.29,Office No.5,Sawant Park,<br/>
                                     Balaji Nagar,Pune Satara Road,<br/>
                                     Pune, Maharashtra, 411043, IN</span>
                                     </Card.Body> 
              </Card>
            </div>
            </Col>
          
              </Col>
                  <Col xs={12} md={6} className='con-col1'>
                  <Container className='con'>
                        
                        <Form>
                          <Row className='mt-4'>
                            <Col>
                            <FloatingLabel controlId="floatingInput" label="Enter Name" className="mb-3 name">
                                  <Form.Control type="text" placeholder="Enter Your Name" className='name'/>
                            </FloatingLabel>
                            </Col>
                            <Col>
                            <FloatingLabel controlId="floatingInput" label="Enter Email" className="mb-3 email">
                                  <Form.Control type="text" placeholder="Enter Your Email" className='email'/>
                            </FloatingLabel>
                            </Col>
                          </Row>

                          <FloatingLabel controlId="floatingInput" label="Subject" className="mb-3 subject">
                                  <Form.Control type="text" placeholder="Subject" className='subject'/>
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="Message" className="mb-3 msg">
                                  <Form.Control type="text" placeholder="Message" className='msg'/>
                            </FloatingLabel>

                            <Button className='con-btn btn-dark'> SEND MESSAGE</Button>
                        </Form>
                        </Container>
                  </Col>
                  
                 
              </Row>
            </Card>         
          <Row>
        
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7568.780074076517!2d73.85032959206745!3d18.465983780467838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xab05467342c7c971%3A0x65f75afd39399fe8!2sIrtcm%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1714641763147!5m2!1sen!2sin" 
            width="100%" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
           </iframe>
          </Row>
         
    </Container>
    </div>
  )
}

export default ContactUs