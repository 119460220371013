
import '../component/AboutUs.css';
import React, { Component } from 'react';
// import Col from 'react-bootstrap/Col';
// import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
// import Row from 'react-bootstrap/Row';

import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
export class AboutUs extends Component {
  static propTypes = {}

  render() {
    return (
        <div>
        
        <div className='headerc d-flex align-items-center justify-content-center'>

<Breadcrumb className='custom-breadcrumb'>

    <Breadcrumb.Item>
    <Link  to="/" className="" >Home</Link>

    </Breadcrumb.Item>
   
    <Breadcrumb.Item>
    <Link  to="/aboutus" className="active" >About Us</Link>
    {/* <a as="Link" to="/service" className="active" >Service</a> */}
    
     </Breadcrumb.Item>
    
</Breadcrumb>

</div>
       <section id="about" className="py-5">
         <div className="container">
           <div className="row">
             <div className="col-md-6">
               <h2 className='mt-0 aboutusbg servicetext mt-4'>About Us</h2>
               <p className='taj'>IRTCM INDIA  Private Limited is a leading provider of innovative technology solutions. We specialize in delivering high-quality software development, web and mobile applications, cloud solutions, data analytics, and cybersecurity services.</p>
               <p className='taj'>At IRTCM INDIA Private Limited IT Company, we are committed to helping our clients achieve their digital transformation goals. Our team of experts works collaboratively with businesses to understand their unique needs and challenges, delivering customized solutions that drive growth and success.</p>
             </div>
             <div className="col-md-6">
               {/*<!-- Add an image of your team or office here -->
               <!-- Example: */}
               {/*<img src="path/to/your/image.jpg" alt="Company Image" className="img-fluid"/>*/}
               <img className="rounded tac ms-5" src="../img/new/Ab.png" style={{'height': '80%'}} alt="Service_Image"/>
               
             </div>
           </div>
         </div>
       </section>
     
       {/*<!-- Mission and Vision Section -->*/}
       <section id="mission-vision" className="py-0">
       {/* <Container>
      <Row>
       
        <Col className='text-center'>
          <Image src="../img/diamond.png" roundedCircle  style={{'height':'70px'}}/>
        </Col>
        
      </Row>
    </Container> */}
         <div className="container">
           <div className="row">
             <div className="col-md-6 ">
            <div className='text-center'>
            {/* <Image src="../img/diamond.png" className='img-thumbnail'  style={{'height':'55px'}}/> */}
        
                <h3 className='servicetext'>Mission</h3>
            </div>
          
               <p>Our mission is to empower businesses with innovative technology solutions that drive efficiency, productivity, and growth.</p>
             </div>
             <div className="col-md-6">
             <div className='text-center'>
            {/* <Image src="../img/vision.png" className='img-thumbnail'   style={{'height':'55px'}}/> */}
        
            <h3 className='servicetext'>Vision</h3>
            </div>
              
               <p>We envision a future where technology knows no bounds and businesses thrive in the digital world.</p>
             </div>
           </div>
         </div>
       </section>
     
       {/*<!-- Core Values Section -->*/}
       <section id="core-values" className="py-5">
         <div className="container">
           <h3 className="text-center textccolor mb-5" >Our Core Values</h3>
           <div className="row">
             <div className="col-md-4">
             
             <div className='text-center'>
            {/* <Image src="../img/bulb.png" className='img-thumbnail'   style={{'height':'65px'}}/> */}
        
            <h4 className='servicetext'>Innovation</h4>
            </div>
              
               <p>We foster a culture of continuous learning and curiosity, pushing the boundaries of what's possible to provide our clients with groundbreaking solutions.</p>
             </div>
             <div className="col-md-4">

             <div className='text-center'>
            {/* <Image src="../img/integrity.png" className='img-thumbnail'   style={{'height':'60px'}}/> */}
        
            <h4 className='servicetext'>Integrity</h4>
            </div>
              

               <p>Trust is the foundation of our relationships. We uphold the highest ethical standards in all our interactions with clients, partners, and employees.</p>
             </div>
             <div className="col-md-4">
             <div className='text-center'>
            {/* <Image src="../img/achievement.png" className='img-thumbnail'    style={{'height':'60px'}}/> */}
        
            <h4 className='servicetext'>Client Success</h4>
            </div>
               
               <p>Our success is tied to the success of our clients. We are dedicated to understanding their needs and delivering solutions that exceed expectations.</p>
             </div>
           </div>
         </div>
       </section>
     
       {/*<!-- Contact Section -->*/}
       
     
     {/**end of  */}
     {/**
     <section id="about " className="about mt-5 ">
         <div className="container" data-aos="fade-up">
     
           <div className="row">
             <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
               <img src='../img/5.jpg' className="img-fluid" alt=""/>
             </div>
             <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
               <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3>
               <p className="fst-italic">
                 Living In The Todays Digital World, A Website Is A Necessity For Any Business. Flowlen Infotech  & Management Pvt. Ltd. Will Support To Develop Innovation And Technology To Start-ups.
                 Flowlen Infotech & Management Pvt. Ltd. Is One Of The Fastest Growing Company That Have Been Providing Web Designing And Software Development Solutions Company In India.
                 
               </p>
              
              
               <div >
                 <a href="/contactus" className="btn cbgcolor">Join Us Now!</a>
               </div>
     
             </div>
           </div>
     
         </div>
       </section>
      */}
     
     
     
           </div>
    )
  }
}

export default AboutUs