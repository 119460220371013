import React from 'react'
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container'
import { Row,Col,Button } from 'react-bootstrap';
import './Register.css';

export default function Forgot() {
  return (
    <div>
       <Container>
      <Row>
      <Col xs={12} md={6}> 
          <img  className='img-fluid' src="./img/forgot.jpg"  alt="" max-width='100%' height='auto'/>
      </Col>
      <Col xs={12} md={6} className='forgot'>
        
            <h1 className='mt-3'> Forgot  Your Password?</h1>
            <Form>
            <Form.Group className="mb-3" controlId="formGridAddress2">
            <Form.Label>Email</Form.Label>
            <Form.Control />
      </Form.Group>
            <Form.Group className="mb-3" controlId="formGridAddress2">
            <Form.Label>Email OTP</Form.Label>
            <Form.Control />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridAddress2" >
            <Form.Label >New Password</Form.Label>
            <Form.Control />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridAddress2">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control />
      </Form.Group>

   
    
      <Button className='mt-3 mb-3' variant="primary" type="submit">
        Reset Password
      </Button>
      
    </Form>
   


      </Col>
        </Row>
        </Container>
    </div>
  )
}
