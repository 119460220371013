import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';

function Emp_Primary_Info() {
  const [formData, setFormData] = useState({
    employeeName: '',
    mobile: '',
    email: '',
    gender: '',
    dob: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send POST request to the backend
    fetch('http://192.168.0.119:8585/addEmployeeDetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      alert('Data submitted successfully');
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div className="P1">
      <section className="my-lg-9 my-5">
        <div className="container">
          <div className="row-lg-12 offset-lg-1 col-md-10 justify-content-right align-items-right">
            <div className="col-lg-12 offset-lg-0 col-md-12 justify-content-right align-items-right">
              <div className="card w-76 bg-light card-body p-5">
                <h3 className="mb-3 d-flex justify-content-center" style={{ fontWeight: 'bold' }}>Primary Information</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Name:</Form.Label>                                           
                    <Form.Control
                      type='text'
                      name='employeeName'
                      placeholder="Enter full Name"
                      value={formData.employeeName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} className='mb-3'>
                      <Form.Label>Mobile Number:</Form.Label>
                      <Form.Control
                        type='text'
                        name='mobile'
                        placeholder="Enter Mobile Number"
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} className='mb-3'>
                      <Form.Label>Email:</Form.Label>
                      <Form.Control
                        type='email'
                        name='email'
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} className='mb-3'>
                      <Form.Label>Gender:</Form.Label></Form.Group>
                      <Form.Group as={Col} className='mb-3'>
                      <Form.Check as={Col} className='mb-3'
                        type='radio'
                        label='Male'
                        name='gender'
                        value='Male'
                        checked={formData.gender === 'Male'}
                        onChange={handleChange}
                      /></Form.Group>
                       <Form.Group as={Col} className='mb-3'>
                      <Form.Check 
                        type='radio'
                        label='Female'
                        name='gender'
                        value='Female'
                        checked={formData.gender === 'Female'}
                        onChange={handleChange}
                      /></Form.Group>
                       <Form.Group as={Col} className='mb-3'>
                      <Form.Check
                        type='radio'
                        label='Other'
                        name='gender'
                        value='Other'
                        checked={formData.gender === 'Other'}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Form.Label>Date Of Birth:</Form.Label>
                  <Form.Control className='mb-3'
                    type='date'
                    name='dob'
                    placeholder=""
                    value={formData.dob}
                    onChange={handleChange}
                  />
                  <Button type='submit' variant='primary' style={{ width: '100%' }}>Add</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Emp_Primary_Info;
