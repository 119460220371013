import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';


function Emp_Salary_Details() {
  
  const [earnings, setEarnings] = useState({
    basicAndda: '',
    specialAllowance: '',
    hra: '',
    conveyance: '',
    otherAllowance: '',
    overtimeAllowance: '',
    totalEarnings: '',
  });

  const [deductions, setDeductions] = useState({
    providentFund: '',
    mlwf: '',
    esi: '',
    loansOrAdvances: '',
    professionTax: '',
    tsdOrIt: '',
    otherDeduction: '',
    totalDeduction: '',
    netSalary: '',
  });

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in earnings) {
      setEarnings((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name in deductions) {
      setDeductions((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
 
  const handleSubmit =  (e) => {
    const employeeSalaryDetails = {
      earnings,
      deductions,
    };

    // try {
    //   const response = await axios.post('http://192.168.0.119:8585/calculate', employeeSalaryDetails);
    //   console.log('Response:', response.data);
   
    // } catch (error) {
    //   console.error('Error sending salary details:', error);
    // }
    e.preventDefault();
    fetch('http://192.168.0.119:8585/calculate',{
        method:'POST',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify(employeeSalaryDetails)
    })
    .then(response => response.json())
    .then(data => {
        console.log(data);
        alert('Data Submitted successfully');
    })
    .catch(error => {
        console.error('Error:',error);
    });
  
  };

  return (
    <div>
      <section className="my-lg-9 my-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 offset-lg-0 col-md-12">
              <div className="card bg-light card-body p-5">
                <h3 className="mb-3 d-flex justify-content-center" style={{ fontWeight: 'bold' }}>
                  Employee Salary Details
                </h3>
                <Row className="table-responsive-sm">
                  <Col style={{ margin: 0, padding: 0 }}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan={2} className="text-center">Earnings</th>
                        </tr>
                        <tr>
                          <th>Earning Key</th>
                          <th>Earning Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Basic & DA</td>
                          <td>
                            <input
                              type="text"
                              name="basicAndda"
                              value={earnings.basicAndda}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Special Allowance</td>
                          <td>
                            <input
                              type="text"
                              name="specialAllowance"
                              value={earnings.specialAllowance}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>HRA</td>
                          <td>
                            <input
                              type="text"
                              name="hra"
                              value={earnings.hra}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Conveyance</td>
                          <td>
                            <input
                              type="text"
                              name="conveyance"
                              value={earnings.conveyance}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Other Allowance</td>
                          <td>
                            <input
                              type="text"
                              name="otherAllowance"
                              value={earnings.otherAllowance}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Overtime Allowance</td>
                          <td>
                            <input
                              type="text"
                              name="overtimeAllowance"
                              value={earnings.overtimeAllowance}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Total Earnings</td>
                          <td>
                            <input
                              type="text"
                              name="totalEarnings"
                              value={earnings.totalEarnings}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col style={{ margin: 0, padding: 0 }}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th colSpan={2} className="text-center">Deductions</th>
                        </tr>
                        <tr>
                          <th>Deduction Key</th>
                          <th>Deduction Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Provident Fund</td>
                          <td>
                            <input
                              type="text"
                              name="providentFund"
                              value={deductions.providentFund}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>MLWF</td>
                          <td>
                            <input
                              type="text"
                              name="mlwf"
                              value={deductions.mlwf}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>E.S.I.</td>
                          <td>
                            <input
                              type="text"
                              name="esi"
                              value={deductions.esi}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Loans/Advances</td>
                          <td>
                            <input
                              type="text"
                              name="loansOrAdvances"
                              value={deductions.loansOrAdvances}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Profession Tax</td>
                          <td>
                            <input
                              type="text"
                              name="professionTax"
                              value={deductions.professionTax}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>TSD/IT</td>
                          <td>
                            <input
                              type="text"
                              name="tsdOrIt"
                              value={deductions.tsdOrIt}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Other Deduction</td>
                          <td>
                            <input
                              type="text"
                              name="otherDeduction"
                              value={deductions.otherDeduction}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Total Deduction</td>
                          <td>
                            <input
                              type="text"
                              name="totalDeduction"
                              value={deductions.totalDeduction}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>NET Salary</td>
                          <td>
                            <input
                              type="text"
                              name="netSalary"
                              value={deductions.netSalary}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  style={{ width: '100%' }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Emp_Salary_Details;
