
import React, { Component } from 'react'
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
export class Career extends Component {
  msgcolor="";
  radioChecked_f=false;
  constructor(props) {
    super(props);
    this.state = {msgcolor: "success"};
    this.state = {
      name: 'React',
      data:["Java Full Stack Developer","Flutter Developer","Fresher","Web Developer","Android Developer"],
      selected:''
    };
  }

  
  render() {
  
    const handleClick = () => {};
    const handleChange = () => {};
    const radiohandleChangeData = () => {};
 
    return (
      <div>
      <div className='headerc d-flex align-items-center justify-content-center'>

<Breadcrumb className='custom-breadcrumb'>

    <Breadcrumb.Item>
    <Link  to="/" className="" >Home</Link>

    </Breadcrumb.Item>
   
    <Breadcrumb.Item>
    <Link  to="/career" className="active" >Career</Link>
    {/* <a as="Link" to="/service" className="active" >Service</a> */}
    
     </Breadcrumb.Item>
    
</Breadcrumb>

</div>

<div className="container mb-5 mt-5">

  {/* {/<!--heading start-->/} */}
  



  {/* <!--heading end--> */}  
  <div className="row justify-content-center p-2">
   
  <div className="col-sm-12 col-md-6 col-lg-4  text-center  border border-warning p-2" >
              		<p className="mb-4  text-danger fw-bold ">Kindly fill-up this recruitment application form to apply for job.</p>

                      <div>
                            
                         
                             <ol className="list-group list-group-numbered">
  <li className="list-group-item fw-bold navtextcolor">Full Stack Java Developer</li>
 
</ol>
                        
                      </div>

                     

                    
      </div>
      {/**end of col */}
    <div className="col-sm-12 col-md-6  col-lg-8">
      



    <form className="row g-3 justify-content-center ctext">
   
    
    <Badge  style={{
        fontFamily: '-apple-system',
        fontSize: "1.5rem",
        fontWeight: 1.5,
        lineHeight: 1.5,
        padding: "0 1em"
      }} bg={this.state.msgcolor} id="msg2"></Badge>
    

    
          <div className="col-md-5">
            <label for="fname" className="form-label">First Name</label>
            <input type="text" className="form-control" id="fname"/>
          </div>

          <div className="col-md-5">
            <label for="lname" className="form-label">Last Name</label>
            <input type="text" className="form-control" id="lname"/>
          </div>

          <div className="col-md-5">
            <label for="contactnumber" className="form-label">Contact Number</label>
            <input type="number" className="form-control" id="contactNumber"/>
          </div>

          <div className="col-md-5">
            <label for="emailid" className="form-label">Email ID</label>
            <input type="email" className="form-control" id="emailId"/>
          </div>
          <div className="col-md-5">
            <label for="state" className="form-label">State</label>
            <input type="text" className="form-control" id="state"/>
          </div>
          <div className="col-md-5">
            <label for="city" className="form-label">City</label>
            <input type="text" className="form-control" id="city"/>
          </div>
        
          <div className="col-md-5 ">
        
          {/*  */}
            <label for="gender" className="form-label g-2">Gender </label>

            <div className="form-check form-check-inline mb-0 g-2">
              <input className="form-check-input " type="radio" name="inlineRadioOptions" id="femalegender"
                value="Female" checked={this.state.radioChecked_f} onClick={radiohandleChangeData}/>
              <label className="form-check-label g-2" for="femaleGender">Female</label>
            </div>
        
            <div className="form-check form-check-inline mb-0 ">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="maleGender"
                value="Male" checked={this.state.radioChecked_m} onClick={radiohandleChangeData}/>
              <label className="form-check-label" for="maleGender">Male</label>
            </div>
        
            <div className="form-check form-check-inline mb-0">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="otherGender"
                value="Other" checked={this.state.radioChecked_o} onClick={radiohandleChangeData}/>
              <label className="form-check-label" for="otherGender">Other</label>
            </div>
{/*  */}



          </div>





          <div className="col-md-5">

          <select className="form-select" aria-label="Default select example" id="experienceType">
  <option selected>Select Work Profile</option>
  <option value="Fresher">Fresher</option>
  <option value="Experince">Experince</option>
  
</select>
          
          </div>
          <div className="col-md-5">


 <select className="form-control" value={this.state.selected} id="jobProfile" name="Auditee" onChange={handleChange.bind(this)}>
          <option>Select Job Profile</option>
          {this.state.data.map(data=><option>{data}</option>)}
        </select>

</div>


           <div className="col-md-5 ms-2">
       
            <input type="button" onClick={handleClick} value="Submit" className="btn btn-primary border-1"/>
                          
          </div>

        </form>

    </div>
    {/**end of col */}

  </div>
  {/**end of row */}
</div>

      </div>

    )
  }
}

export default Career