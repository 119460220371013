import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row,Col } from 'react-bootstrap';
import { useState } from "react";
function Time_Tracking() {
    const CurrentDate=new Date().toDateString();
    // console.log(CurrentDate);
    // let date1='number';
    // const today=()=>{
    //     date1='date';
    // }
    
       
    const[tasks,settasks]=useState([]);
    var[taskinput,settaskinput]=useState('');
    taskinput=<input type='text'></input>;
    settaskinput=<input type='text'></input>;
    const addtask=()=>
    {
        // if(taskinput.trim()!=='')
        // {
        //     settasks([...tasks,taskinput]);
        //     settaskinput('');
        // }
        
          
        
    };
    
    const removetask=(index)=>
    {
        const utasks=[...tasks];
        utasks.splice(index,1);
        settasks(utasks);
    };
    
    {/* return(
        <div>
            <input type="text" value={taskinput}
            onChange={(e)=> settaskinput(e.target.value)
            }/>
    
            <button onClick={addtask}>addtask</button>
    
            <ul>
            {tasks.map((task,index)=>(
            <li key={index}>task
            <button onClick={()=>
            removetask(index)}>
    
            </button>
            </li>    
           ) )}
            </ul>
        </div>
    )
    }; */}
    
  return (
    <div>
    <section className="my-lg-9 my-5 ">
      <div className="container">
          <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 offset-lg-1 col-md-12">
                    <div className="card bg-light card-body p-5">               
                              <h3 className="mb-4 d-flex justify-content-center" style={{fontWeight:'bold'}}>Attendance</h3>
                              <Form>
                                <Form.Group className='mb-3'>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Form.Label>Employee Id:</Form.Label><br></br>
                                            <Form.Control className='mb-3' style={{width:'100%'}} type='text' placeholder='Employee Id'></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Form.Label>Date:</Form.Label><br></br>
                                             <Form.Control className='mb-3' value={CurrentDate}  type='text' placeholder=''></Form.Control>
                                        </Col>
                                    </Row>
                                    {/* <textarea
                                            
                                            placeholder="Enter your text here..."
                                            /> */}
                                    
                                     {/* <select style={{width:"50%"}} className='mb-3'>
                                        <option>select</option>
                                        <option value='1'>Pending</option>
                                        <option value='2'>Approved</option>
                                        <option value='1'>Rejected</option>
                                    </select> <br></br> 
                                                                       */}
                                    <Row>
                                        <Col xs={12} md={6}>
                                        <Form.Label>Clock-In:</Form.Label><br></br>
                                        <Form.Control className='mb-3' style={{width:'100%'}} type='time' placeholder='Employee Id'></Form.Control>
                                        </Col>
                                        <Col xs={12} md={6}>
                                        <Form.Label>Clock-Out:</Form.Label><br></br>
                                        <Form.Control className='mb-3' style={{width:'100%'}} type='time' placeholder='Employee Id'></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Label>Tea Break:</Form.Label>
                                        <Col xs={12} md={6}>
                                         
                                            <Form.Control type='time' placeholder='from'></Form.Control>
                                        </Col>
                                        <Col xs={12} md={6}>
                                         
                                         <Form.Control type='time' placeholder='from'></Form.Control>
                                     </Col>
                                    </Row>
                                  
                                    <Row className='mb-3'>
                                        <Form.Label>Lunch Break:</Form.Label>
                                        <Col xs={12} md={6}>
                                         
                                            <Form.Control type='time' placeholder='from'></Form.Control>
                                        </Col>
                                        <Col xs={12} md={6}>
                                         
                                         <Form.Control type='time' placeholder='from'></Form.Control>
                                     </Col>
                                    </Row>
                                    <Form.Label>Overtime:</Form.Label>
                                    <Form.Control type='number' placeholder="in hrs " className='mb-3' />
                                    <Form.Label>Notes:</Form.Label><br/>
                                      
                                    <Form.Control as='textarea' rows={2} placeholder="-----" />

                                   
                                    </Form.Group>
                                    <Button variant='primary' style={{width:'100%'}}>Submit</Button>
                              </Form>
                        </div>
                </div>
            </div>
        </div>
    </section>
</div>
  )
}

export default Time_Tracking