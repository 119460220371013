import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Bank_Details = () => {
  const [formData, setFormData] = useState({
    bankName: '',
    bankBranch: '',
    bankAccountNo: '',
    confirmAccountNo: '',
    ifscCode: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.bankAccountNo !== formData.confirmAccountNo) {
      alert('Account numbers do not match');
      return;
    }

    fetch('http://192.168.0.119:8585/addBankDetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to submit data');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        alert('Data submitted successfully');
        setFormData({
          bankName: '',
          bankBranch: '',
          bankAccountNo: '',
          confirmAccountNo: '',
          ifscCode: '',
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an error submitting the data');
      });
  };

  return (
    <div className="P1">
      <section className="my-lg-9 my-5">
        <div className="container">
          <div className="row-lg-12 offset-lg-1 col-md-10 justify-content-right align-items-right">
            <div className="col-lg-12 offset-lg-0 col-md-12 justify-content-right align-items-right">
              <div className="card w-76 bg-light card-body p-5 text-start">
                <h3 className="mb-3 d-flex justify-content-center" style={{ fontWeight: 'bold' }}>
                  Bank Details
                </h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Bank Name:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Bank Name"
                            name="bankName"
                            value={formData.bankName}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Branch Name:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Branch Name"
                            name="bankBranch"
                            value={formData.bankBranch}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Account Number:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Account Number"
                            name="bankAccountNo"
                            value={formData.bankAccountNo}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Confirm Account Number:</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm Account Number"
                            name="confirmAccountNo"
                            value={formData.confirmAccountNo}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>IFSC Code:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter IFSC Code"
                            name="ifscCode"
                            value={formData.ifscCode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Button
                    variant="primary"
                    style={{ width: '100%' }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Bank_Details;
