import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card'
import { GrCheckboxSelected } from "react-icons/gr";

import './Home.css';
 
 export default function Home() {
  return (
    <div className='home mt-2'>
  <Container>
      <Row>
        <Col xs={12} md={6}>  <h1 className='ht1'>We offer modern<br/>solutions for growing<br/>your business</h1>
           <p className='ht2 '>We believe that every business is unique and requires a personalized approach when it comes to technology solutions. That's why we work closely with our clients to gain a deep understanding of their requirements and design software applications that cater to their specific workflows.</p>
           </Col>
        <Col xs={12} md={6}> 
       
        <img className='img1 img-fluid' src="./img/new/home2.png" animation="grow" alt="Laptop" max-width=' 100%' height='auto'/>
       
        </Col>
      </Row>
</Container>
{/* start */}

<Container fluid>
      <Row>
      <h1 className='F1'>Features</h1>
           <p className='F2 '>Different Services we provide</p> 
        <Col xs={12} md={6}>
        <img className='img1 img-fluid' src="../img/new/newservice.png"  alt="Service"  max-width=' 80%' height='auto'/>
        </Col>
        <Col xs={12} md={6}> 
        <div className='Cd1'>
          <Row>
            
            <Col>

            <Card className=' mt-2 mb-4  shadow border-light'>
          <Card.Body > <GrCheckboxSelected className='CA1' size={20} style={{float: 'left' }}/>Website development</Card.Body>
            </Card>
          </Col>
            <Col> 
             <Card className='mt-2 mb-6 shadow border-light'>
      <Card.Body><GrCheckboxSelected  className='CA2' size={20} style={{float: 'left' }}/>Web App Development</Card.Body>
    </Card>
    </Col>
          </Row>
          <Row>
          <Col> 
           <Card className='xs={12} md={6} mt-2 mb-6  shadow border-light'>
      <Card.Body><GrCheckboxSelected className='CA3' size={20} style={{float: 'left' }}/>Custom Software development</Card.Body>
    </Card>
    </Col>
            <Col> 
             <Card className='xs={12} md={6} mt-2 mb-4 shadow border-light'>
      <Card.Body><GrCheckboxSelected className='CA4' size={20} style={{float: 'left' }}/>Mobile App Development</Card.Body>
    </Card>
    </Col>
          </Row>
          <Row>
          <Col> 
           <Card className='xs={12} md={6} mt-2 mb-4 shadow border-light'>
      <Card.Body><GrCheckboxSelected className='CA5' size={20} style={{float: 'left' }}/>Digital Marketing </Card.Body>
    </Card>
    </Col>
            <Col> 
             <Card className='xs={12} md={6} mt-2 mb-4 shadow border-light'>
      <Card.Body><GrCheckboxSelected className='CA6' size={20} style={{float: 'left' }}/>Android Application Development</Card.Body>
    </Card>
    </Col>
    
          </Row>
          </div>
        </Col>
       
      </Row>
    </Container>
{/* end */}
<Container fluid className= ''>
      <Row>
        
        <Col xs={12} md={6}>  <h1 className='ht1'>About Us</h1>
           <p className='ht2 '>IRTCM India Pvt. Ltd. is a privately owned mobile development company based in Pune, India. IRTCM India Private Limited is a leading provider of innovative technology solutions. We specialize in delivering high-quality software development, web and mobile applications, cloud solutions, data analytics, and cybersecurity services.At IRTCM India Private Limited IT Company, we are committed to helping our clients achieve their digital transformation goals. Our team of experts works collaboratively with businesses to understand their unique needs and challenges, delivering customized solutions that drive growth and success.
Let’s work together. Come Join Us!</p>  </Col>
      <Col xs={12} md={6}>
        <img className='img1 img-fluid' width='60%' height='auto' src="./img/new/Ab.png"  alt="About Us"  />
        </Col>
       
      </Row>
    </Container>

<Container>

<Row>

    <h5  className='nm'>We Provide</h5>
    <h1 className='nm'>Services</h1>
    <div className="row row-cols-1 row-cols-md-3 g-4">
     <Col>
    
         <Card className="id1 shadow">
            <Card.Img variant="top" src="../img/web1.png" class="col-md-4 me-auto ms-auto" style={{ width: '10rem'}}/>
            <Card.Body>
              <Card.Title>Web Development</Card.Title>
              <Card.Text>
              Our team is developing Front-end web applications since very long and have worked with number of business solutions like ERP, CRM and AR. For us it is the practice of converting data to a graphical interface, through the use of HTML, CSS, and JavaScript, so that users can view and interact with that data. </Card.Text>
            </Card.Body>
          </Card>
        
          </Col>
        
        <Col>
       
          <Card className="id2  shadow">
            <Card.Img variant="top" src="../img/custom.jpg" class="col-md-4 me-auto ms-auto" style={{ width: '10rem' }}/>
            <Card.Body>
              <Card.Title>Custom Software Development Services</Card.Title>
              <Card.Text>
              With a focus on understanding the client's needs and objectives, a reliable software development company can deliver innovative and efficient solutions that align with the client's processes.  </Card.Text>
            </Card.Body>
          </Card>
        
          </Col>
         <Col>
        
          <Card className="id3    shadow ">
            <Card.Img variant="top" src="../img/digital1.png" class="col-md-4 me-auto ms-auto"  style={{ width: '10rem' }}/>
            <Card.Body>
              <Card.Title>Digital Media</Card.Title>
              <Card.Text>
              Broaden the digital footprint of your business! How about taking a step forward. Our services come under four headings – Marketing Contact, Digital Presence, Sales Enablement & Marketing Technology.</Card.Text>
            </Card.Body>
          </Card>
         
         </Col>
         </div>
    </Row>
    <div className='P1'>
    <Row >
    <div className="row row-cols-1 row-cols-md-3 g-4">
     <Col>
   
         <Card className="id4    shadow ">
            <Card.Img variant="top" src="../img/product.jpg" class="col-md-4 me-auto ms-auto" style={{ width: '10rem', position:'center' }}/>
            <Card.Body>
              <Card.Title>Software Product Development Services</Card.Title>
              <Card.Text>
              Custom software development services offer tailored solutions to businesses in need of specific applications. 
              </Card.Text>
            </Card.Body>
          </Card>
         
          </Col>
        <Col>
      
          <Card className=" id5   shadow">
            <Card.Img variant="top" src="../img/mb1.png" class="col-md-4 me-auto ms-auto mt-3" style={{ width: '100px' }}/>
            <Card.Body>
              <Card.Title>Mobile Development</Card.Title>
              <Card.Text>
              Our understanding of creating better apps starts by training ourselves to think about the things that will make products extremely valuable for us as well as our clients.
              </Card.Text>
            </Card.Body>
          </Card>
        
          </Col>
         <Col>
      
          <Card className=" id6 shadow ">
            <Card.Img variant="top" src="../img/crm.jpg" class="col-md-4 me-auto ms-auto"  style={{ width: '10rem' }}/>
            <Card.Body>
              <Card.Title>Custom CRM Development Services</Card.Title>
              <Card.Text>
              Whether it's building a customer relationship management system or developing an e-commerce platform from scratch, custom software development services provide businesses .
              </Card.Text>
            </Card.Body>
          </Card>
        
         </Col>
        </div>
    </Row>
    </div>
    </Container >
 
        </div>
        )
}
