import React, { useState } from 'react';
import { Container, Card, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { PiUserCircleFill } from 'react-icons/pi';
import { FaUser, FaLock, FaKey } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Register.css';


  // code1
  const LoginForm = () => {
    const [username, setUsernameOrEmail] = useState('');
    const [password, setPassword] = useState('');
    const[errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
  
  
  const handleLogin = async() =>{
      console.log("usernameOrEmail:"+username);
      console.log("password:"+password);
    
      const response = await axios.post('https://wpm.loginrich.com/api/auth/login',{
          'usernameOrEmail' : username,
          'password' : password,
         
      })
      .then((response)=>
      {   
        console.log('api response:',response);
          const { accessToken } = response.data;
          console.log(accessToken)
  
          sessionStorage.setItem("token", accessToken);
          if(response.status===200)
          {
            navigate('/dashboard');
          }
        })
  .catch((error) =>{
    setErrorMessage('Invalid username or password')
      console.log("error",error)
      
  });
  }
  // code2
  
  return (
    <Container>
      <Card className='con-card'>
        <Row className='justify-content-center'>
          <Col xs={12} md={6} className='con-col1'>
            <Container className='con'>
              <PiUserCircleFill style={{ height: 50, width: 50 }} />
              <h2 className='mt-2'>Login</h2>
              <Form>
                <Form.Group>
                  <Form.Label>Username or Email</Form.Label>
                  <InputGroup className='mb-3'>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                    <Form.Control
                      type='text'
                      placeholder='Username or Email'
                      value={username}
                      onChange={(e) => setUsernameOrEmail(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <InputGroup className='mb-3'>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                {errorMessage && (
                  <div className='alert alert-danger'>{errorMessage}</div>
                )}

                <Button className='mb-3 btn btn-primary' type='button' onClick={ handleLogin}>
                  <FaKey /> Login
                </Button>
                <Link className='mb-2 d-flex justify-content-center' to='/forgot'>
                  Forgot Password?
                </Link>
              </Form>
            </Container>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default LoginForm;
