import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {  useNavigate } from 'react-router-dom';
import { FaTh, FaUserAlt } from 'react-icons/fa';
import { RiShutDownLine } from 'react-icons/ri';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton to="/dashboard" >
      <ListItemIcon>
        <FaTh />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>

    <ListItemButton  to="/dashboard/emp"> 
      <ListItemIcon>
        <FaUserAlt />
      </ListItemIcon>
      <ListItemText  primary="Employee" />
    </ListItemButton>
  </React.Fragment>
);

const ListItemLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    console.log('Logout called');
    sessionStorage.removeItem('token');
    navigate('/');
  };

  return (
    <ListItemButton onClick={logout}>
      <ListItemIcon>
        <RiShutDownLine />
      </ListItemIcon>
      <ListItemText primary="LOGOUT" />
    </ListItemButton>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <ListItemLogout />
  </React.Fragment>
);
