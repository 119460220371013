import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Card from 'react-bootstrap/Card';
import './Mobile_App_Development.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FcSearch } from "react-icons/fc";
import { FcMultipleSmartphones } from "react-icons/fc";
import { FcNews } from "react-icons/fc";
import { MdOutlineEmail } from "react-icons/md";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Digital_Marketing() {
  return (
    <div>
       
<div className='headerc d-flex align-items-center justify-content-center'>

<Breadcrumb className='custom-breadcrumb'>

    <Breadcrumb.Item>
    <Link  to="/" className="" >Home</Link>

    </Breadcrumb.Item>
   
    <Breadcrumb.Item>
    <Link  to="/digital" className="active" >Digital Marketing Service</Link>
    {/* <a as="Link" to="/service" className="active" >Service</a> */}
    
     </Breadcrumb.Item>
    
</Breadcrumb>

</div>     
            <div className=''>
                <div className="col-12 col-md-12 ">
                <Card className='main-title-dg-c'>
                         <h1 className="main-title mt-1">Digital Marketing Service</h1>
                         <p className="desc mb-0">Empower Your Brand, Amplify Your Reach..</p>
                      </Card>
                  
                  </div>
                  
                    <div className='m-3 webdevdiv'>
                        <Row>
                            <Col xs={12} md={12}>
                            </Col>
                            <h2 className='mt-3 text-dark'>Digital Marketing </h2>
                            <p className="desc mt-2">Catalyze Your Digital Success with IRTCM : Where Strategy Meets Innovation</p>
                        </Row>

                     <Row className='mb-5'>
                        <Col xs={12} md={6} className='d-flex justify-content-center align-Items-center mt-auto mb-auto'>
                            <img className='ec2 mb-3' src="../image/digital_media.png"  width='60%'></img>
                        </Col>
                        <Col xs={12} md={6} className='' >
                            <p className='dgmtext1'>At IRTCM, we transcend the conventional norms of digital marketing – we become your strategic ally in achieving your online goals. With a wealth of experience and a finger on the pulse of industry trends, our team is poised to craft customized digital marketing solutions tailored precisely to your needs.
                              <br/><br/>
                              What sets us apart is our unwavering dedication to staying ahead of the curve. We immerse ourselves in the ever-evolving landscape of digital marketing, ensuring that your campaigns benefit from the latest tools, techniques, and trends.
                             <br/><br/>
                             Whether you're aiming for enhanced brand visibility, increased website traffic, or amplified conversion rates, we have the expertise and creativity to make it happen. Partner with us, and let's embark on a journey to unlock the full potential of your digital presence.</p>
                        </Col>
                   </Row>

                    </div>
                    <div className=' '>
                      <Row>
                        <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                          <p className='dgm-dev-ser'>Our Web Development Services</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <p className='ser-txt-dgm '>IRTCM offers comprehensive digital marketing solutions covering SEO, Social Media Marketing, Email Marketing, and beyond. Experience a significant boost in your return on investment (ROI) with impressive real-time conversion rates.</p>
                        </Col>
                      </Row>
                      <Row>
                            <Col xs={12} md={3}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <FcSearch style={{width:'40%',height:'auto',color:'blue'}} className='m-3'></FcSearch>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='websertxt'>Search Engine Optimization (SEO)</p>
                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={3}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <FcMultipleSmartphones style={{width:'40%',height:'auto',color:'blue'}} className='m-3'></FcMultipleSmartphones>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='websertxt'>Social Media Marketing (SMM)</p>
                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={3}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <FcNews style={{width:'40%',height:'auto',color:'blue'}} className='m-3'></FcNews>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='websertxt'>Content Marketing</p>
                                  </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={3}>
                                <Row className=''>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                  <MdOutlineEmail style={{width:'40%',height:'auto',color:'red'}} className='m-3'></MdOutlineEmail>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                                    <p className='websertxt'>Email Marketing</p>
                                  </Col>
                                </Row>
                            </Col>
                            </Row>         
                  </div>
                   <div>
                  <Row>
                    <Col xs={12} md={12}>
                      <p className='dgmslogan text-dark d-flex justify-content-center align-Items-center'>Elevate Your Digital Presence, Amplify Your Success !</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} className='d-flex justify-content-center align-Items-center'>
                      <Button className='getintouch mb-3'>Get In Touch</Button>
                    </Col>
                  </Row>
                </div>
             </div>

       
    </div>
  )
}

export default Digital_Marketing;